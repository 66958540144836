import * as React from "react";
import {Main} from "../../components/Main";
import {Navbar} from "../../components/Navbar";

export const Dashboard = () => {
    return (
        <>
            <Navbar/>
            <Main/>
        </>
    );
}