
export const isApp = (pathName: string) => {
    return pathName.includes('app')
}

/*export const isPrivate = (pathName: string) => {
    return pathName.includes('app') &&
        !pathName.includes('login') &&
        !pathName.includes('forgot') &&
        !pathName.includes('signup') &&
        !pathName.includes('new_password')
}*/
