import {useCustomToast} from "../hooks/useCustomToast";
import {useEffect, useState} from "react";
import {api} from "../api/api";

export const useFetchCategories = () => {
    const toast = useCustomToast({})
    const [categories, setCategories] = useState([])
    const [isLoadingCategories, setIsLoadingCategories] = useState(true)

    const getData = async () => {
        try {
            const res = await api.get('/categories/all')
            setCategories(res.data);
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }

            toast({title: `No se pudo obtener la lista de categorias`, status:'error'})
            setCategories([])
            setIsLoadingCategories(false);
        }

        setIsLoadingCategories(false);
    }

    useEffect(() => {
        getData()
    }, []);

    return {
        categories,
        isLoadingCategories: isLoadingCategories,
    }
}