import {Navbar} from "../../components/Navbar";
import {
    Alert, AlertIcon,
    Badge, Box, Button, Collapse,
    Container,
    Fade,
    HStack,
    IconButton,
    Table,
    TableContainer, Tag,
    Tbody,
    Td, Text,
    Th,
    Thead,
    Tooltip,
    Tr, useColorModeValue,
    useDisclosure, Wrap, WrapItem
} from "@chakra-ui/react";
import {CustomHeading} from "../../components/CustomHeading";
import {User} from "../../interfaces/interfaces";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCopy, faKey, faPenAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import {TableSkeleton} from "../../components/TableSkeleton";
import {DeleteConfirm} from "../../components/DeleteConfirm";
import {useFetch} from "../../hooks/useFetch";
import {useEffect, useRef, useState} from "react";
import {useCustomToast} from "../../hooks/useCustomToast";
import {deleteObjectByID} from "../../helpers/deleteObjectByID";
import {findByParam} from "../../helpers/findByParam";
import {format, parseISO} from "date-fns";
import {DrawerUser} from "../../components/DrawerUser";
import {isSuperUser, isUser} from "../../store/auth/auth";
import {useNavigate} from "react-router-dom";
import {api, statusInternalServerError} from "../../api/api";
import * as React from "react";
import Clipboard from "@react-native-clipboard/clipboard";
import {randomPassword} from "../../utils/password";
import {useSelector} from "react-redux";

export const Users = () => {
    const {data, isLoading, setIsLoading} = useFetch("usuarios", "users")
    const [list, setList] = useState(data as User[])
    const [initialList, setInitialList] = useState(data as User[] | [])
    const toast = useCustomToast({})
    const cancelRef: any = useRef()
    const [infoToDelete, setInfoToDelete] = useState({id: '' as any, description: ''})
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen: isOpenEdition, onOpen: onOpenEdition, onClose: onCloseEdition} = useDisclosure()
    const [selectedUser, setSelectedUser] = useState({} as User)

    const [isPasswordCopied, setIsPasswordCopied] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const {username} = useSelector((state: any) => state.store)

    const copyPassword = () => {
        Clipboard.setString(newPassword)
        setIsPasswordCopied(true)
        setTimeout(() => setIsPasswordCopied(false), 1000)
    }

    const handleOnSubmitComplete = () => {
        getUsers()
    }

    const getUsers = async () => {

        try {
            setIsLoading(true)
            const users = await api.get('users')

            setList(users.data)
            setInitialList(users.data)
        } catch (error: any) {
            if (error.response &&
                error.response.status === statusInternalServerError) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Página fuera de servicio`,
                        status: 'warning'
                    })
                }
                return
            }

            setIsLoading(false);
        }
        setIsLoading(false);
    }

    const handleDelete = async (id: string) => {
        try {
            await deleteObjectByID("users", id)

            setList(list.filter((value: User) => value.ID !== id))
            // setInitialList(initialList.filter((value: Socio) => value.id !== id))
            toast({title: "Usuario eliminado con éxito!", status: "success"})
            onClose()
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            toast({title: "El usuario no pudo ser eliminado!", status: "error"})
        }
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findByParam(initialList, value))
    }

    useEffect(() => {
        setList(data)
        setInitialList(data)
    }, [data]);

    return (
        <>
            <Navbar/>
            <Container mt={8}
                       maxW='100%'>

                <CustomHeading title={'Usuarios'}
                               exportData={list}
                               findParam={handleFindParam}
                               showSearch={true}
                               showAddBtn={true}
                               onOpenUserModal={onOpenEdition}
                />
                <TableContainer overflowY={'scroll'} maxH={'63vh'}>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                            <Thead>
                                <Tr>
                                    <Th>Nombre usuario</Th>
                                    <Th>Rol</Th>
                                    <Th>Institución</Th>
                                    <Th>Fecha de alta</Th>
                                    <Th>Ultima actualización</Th>
                                    <Th>Acciones</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {list?.map((user: User) => (
                                    isSuperUser(user.Role) ? [] :
                                        <Tr key={user.ID}>
                                            <Tooltip label={user.Name}>
                                                <Td maxWidth={'10rem'} textOverflow={'ellipsis'} overflowX={'hidden'}>
                                                    {user.Name}
                                                </Td>
                                            </Tooltip>
                                            <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                                maxWidth={'13rem'}>
                                                <Badge p={'1'} px={'2'} borderRadius={'5'}
                                                       colorScheme={isUser(user.Role) ? 'green' : 'red'}>
                                                    {user.Role}
                                                </Badge>
                                            </Td>
                                            <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                                maxWidth={'13rem'}>{user.InstitutionName ? user.InstitutionName : '-'}</Td>
                                            <Td>{format(parseISO(user.CreatedAt), 'dd-MM-yyyy HH:mm')}</Td>
                                            <Td>{user.UpdatedAt ? format(parseISO(user.UpdatedAt), 'dd-MM-yyyy HH:mm') : '-'}</Td>
                                            <Td>
                                                <IconButton onClick={() => {
                                                    if (isSuperUser(user.Role)) {
                                                        return
                                                    }

                                                    setSelectedUser(user)
                                                    onOpenEdition()
                                                }}
                                                            size={'sm'} mx={1} colorScheme={'yellow'}
                                                            aria-label={`editar ${user.ID}`}
                                                            icon={<FontAwesomeIcon icon={faPenAlt}/>}/>

                                                <IconButton isDisabled={username === user.Name || isUser(user.Role)}
                                                            size={'sm'} mx={1}
                                                            colorScheme={'red'} aria-label={`borrar ${user.ID}`}
                                                            icon={<FontAwesomeIcon icon={faTrash}/>}
                                                            onClick={() => {
                                                                if (isSuperUser(user.Role)) {
                                                                    return
                                                                }

                                                                onOpen()
                                                                setInfoToDelete({
                                                                    id: user.ID, description: user.Name
                                                                })
                                                            }}/>
                                            </Td>
                                        </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Fade>
                </TableContainer>

                <TableSkeleton isLoading={isLoading}/>

                <HStack justify={'space-around'} my={4}>
                    <Badge px={3} py={1} borderRadius={5}
                           colorScheme={'purple'}>Usuarios totales: {list ? list.length : 0} </Badge>
                </HStack>
            </Container>

            <DrawerUser user={selectedUser}
                        onSubmitComplete={handleOnSubmitComplete}
                        isOpen={isOpenEdition}
                        onClose={() => {
                            onCloseEdition()
                            setSelectedUser({} as User)
                        }}/>

            <DeleteConfirm isOpen={isOpen}
                           onClose={onClose}
                           pron={'el'}
                           entity={'usuario'}
                           id={infoToDelete.id}
                           description={infoToDelete.description}
                           onDeleteConfirm={handleDelete}
                           cancelRef={cancelRef}
            />
        </>
    )
}