import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Button,
    Center,
    Divider,
    Fade,
    FormControl,
    Heading,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    LinkBox,
    LinkOverlay,
    useColorModeValue,
    VStack,
    Wrap,
} from '@chakra-ui/react'
import * as React from "react";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faEye, faEyeSlash, faKey, faRightToBracket, faUser} from "@fortawesome/free-solid-svg-icons";
import {welcome} from "../../../helpers/welcome";
import bgImage from "../../../assets/img/pexels-johannes-plenio.webp";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {useNavigate} from "react-router-dom";
import {LoginUser} from "../../../interfaces/interfaces";
import {api} from "../../../api/api";
import {login} from "../../../store/slice";
import {ColorModeSwitcher} from "../../../ColorModeSwitcher";
import {Logo} from "../../../components/Logo";

export const Login = () => {
    const {webName} = useSelector((state: any) => state.store)
    const mailTo = 'mailto:compartiendobienestarush@gmail.com?subject=Obtener%20nueva%20cuenta/contraseña%20para%20el%20ingreso%20a%20'
        + webName

    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const bgColor = useColorModeValue('gray.50', 'gray.900')
    const fontColor = useColorModeValue('gray.900', 'gray.100')

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width < 768;
    const isTab = width >= 768 && width < 1024
    const isHD = width >= 1024 && width < 1440
    const isFullHD = width >= 1440 && width <= 1920

    const dispach = useDispatch()

    const onSubmit = async (event: any) => {
        event.preventDefault();

        setIsLoading(true)

        let loginUser: LoginUser = {
            UserName: userName,
            Password: password
        }

        try {
            const res = await api.post('/login', loginUser)
            dispach(login(res.data))
            navigate("/app", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 401) {
                setTimeout(() => {
                    setIsLoading(false)
                }, 2000)

                if (!toast.isActive("custom-toast")) {
                    toast({title: `El nombre de usuario o la contraseña son incorrectos.`, status: 'error'})
                }
                return
            }

            setTimeout(() => {
                setIsLoading(false)
            }, 2000)

            if (!toast.isActive("custom-toast")) {
                toast({
                    title: `El sistema no esta disponible temporalmente, intente nuevamente en unos minutos.`,
                    status: 'error'
                })
            }
        }
    }

    return (
        <>
            <HStack justify={'flex-end'} p={2}>
                <ColorModeSwitcher justifySelf="flex-end"/>
            </HStack>
            <HStack justify={'center'}>
                <Box bgImage={bgImage}
                     bgSize={'cover'}
                     bgPosition="center"
                     bgRepeat="no-repeat"
                     opacity={'0.07'}
                     zIndex={'-2000'}
                     w={'100vw'}
                     h={'100vh'}
                     position={'fixed'}
                     top={0}
                     left={0}
                />
                <Center w={isMobile ? '90%' : isTab ? '70%' : isHD ? '50%' : isFullHD ? '35%' : '30%'}
                        h={height <= 720 ? 600 : 650}
                        borderRadius={20}>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <VStack>
                            <Logo boxSize={height < 720 ? '190' : '270'} />
                            <Wrap w={isMobile ? '95%' : '70%'}
                                  bg={bgColor}
                                  p={10}
                                  boxShadow={{base: 'none', sm: 'md'}}
                                  borderRadius={20}>
                                <Heading size={'lg'}>{welcome()}</Heading>
                                <FormControl pt={5}>
                                    <InputGroup>
                                        <InputLeftElement width={'3rem'} py={'1.5rem'}>
                                            <Icon color={'lightgray'}> <FontAwesomeIcon icon={faUser}/> </Icon>
                                        </InputLeftElement>
                                        <Input
                                            fontSize={isMobile ? '16' : '18'}
                                            size={'lg'}
                                            color={fontColor}
                                            name={'userName'}
                                            placeholder={"Ingrese su usuario"}
                                            type='text'
                                            value={userName}
                                            onChange={({target}) => setUserName(target.value.toLowerCase())}
                                            onKeyDown={(event) => event.key === 'Enter' ? onSubmit(event) : []}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl py={5}>
                                    <InputGroup>
                                        <InputLeftElement width={'3rem'} py={'1.5rem'}>
                                            <Icon color={'lightgray'}><FontAwesomeIcon icon={faKey}/> </Icon>
                                        </InputLeftElement>
                                        <Input
                                            fontSize={isMobile ? '16' : '18'}
                                            size={'lg'}
                                            color={fontColor}
                                            name={'password'}
                                            placeholder={"Ingrese la contraseña"}
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={({target}) => setPassword(target.value)}
                                            onKeyDown={(event) => event.key === 'Enter' ? onSubmit(event) : []}
                                        />
                                        <InputRightElement width={'3rem'} py={'1.5rem'}>
                                            <IconButton size={'md'}
                                                        color={fontColor}
                                                        colorScheme={'whiteAlpha'}
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        aria-label={'showPassword'}
                                                        icon={showPassword ? <FontAwesomeIcon icon={faEyeSlash}/> :
                                                            <FontAwesomeIcon icon={faEye}/>}/>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <Button
                                        fontSize={isMobile ? '16' : '18'}
                                        w={'100%'}
                                        size={'lg'}
                                        isLoading={isLoading}
                                        onClick={onSubmit}
                                        type={"button"}
                                        colorScheme={'blue'}
                                        leftIcon={<FontAwesomeIcon icon={faRightToBracket}/>}>
                                    Ingresar
                                </Button>
                                <Divider/>
                                <LinkBox w={'100%'}>
                                    <Button w={'100%'} fontSize={14} size={'lg'} variant='solid' colorScheme='gray'
                                            leftIcon={<FontAwesomeIcon icon={faEnvelope} beat={true}/>}>
                                        Solicite su cuenta o contraseña
                                    </Button>
                                    <LinkOverlay href={mailTo}/>
                                </LinkBox>
                            </Wrap>
                        </VStack>
                    </Fade>
                </Center>

            </HStack>
        </>
    )
}
