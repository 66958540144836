import {Image} from "@chakra-ui/react";
import logo from "../assets/img/logo.webp";

export const Logo = ({boxSize = '270'}) => {
    return (
        <>
            <Image src={logo} alt='favicon' boxSize={boxSize}/>
        </>
    );
}
