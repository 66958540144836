import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Fade,
    Heading,
    HStack,
    Image,
    LinkBox,
    LinkOverlay,
    Skeleton,
    Stack,
    Text,
    VStack,
    Wrap
} from "@chakra-ui/react";
import {useCustomToast} from "../hooks/useCustomToast";
import * as React from "react";
import {useEffect, useState} from "react";
import {api, statusInternalServerError} from "../api/api";
import {Institution} from "../interfaces/interfaces";
import {ModalItems} from "./ModalItems";
import {ModalContact} from "./ModalContact";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandHoldingHeart, faHandshake, faList, faPenAlt} from "@fortawesome/free-solid-svg-icons";
import fallBackImage from "../assets/img/not_image.webp";
import {useLocation} from "react-router-dom";
import {CustomHeading} from "./CustomHeading";
import {findInstutuionByParam} from "../helpers/findInstitutionByParam";
import {isApp} from "../helpers/common";
import {useSelector} from "react-redux";
import {isUser} from "../store/auth/auth";

export const Main = ({handleIsLoading = function (boolean: any) {}}) => {
    const toast = useCustomToast({})
    const {pathname} = useLocation()
    const [list, setList] = useState([])
    const [initialList, setInitialList] = useState([])
    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showModalItems, setShowModalItems] = useState(false)
    const [showModalContact, setShowModalContact] = useState(false)
    const [selectedInstitution, setSelectedInstitution] = useState([] as any)

    const {role, username} = useSelector((state: any) => state.store)

    const getHomeInfo = async () => {

        try {
            setIsLoading(true)
            handleIsLoading(true)

            let instsPath = "/web/institutions"
            let catPath = "/web/categories"
            if (isApp(pathname)) {
                instsPath = "/institutions"
                catPath = "/categories"
            }

            const insts = await api.get(instsPath)
            const categories = await api.get(catPath)

            setList(insts.data)
            setInitialList(insts.data)
            setCategories(categories.data)
        } catch (error: any) {
            if (error.response &&
                error.response.status === statusInternalServerError) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Página fuera de servicio`,
                        status: 'warning'
                    })
                }
                return
            }

            handleIsLoading(false)
            setIsLoading(false);
        }
        handleIsLoading(false)
        setIsLoading(false);

    }

    useEffect(() => {
        getHomeInfo()
    }, []);

    const handleModalItems = (institution: Institution) => {
        setShowModalItems(true)
        setSelectedInstitution(institution)
    }

    const handleModalContact = (institution: Institution) => {
        setShowModalContact(true)
        setSelectedInstitution(institution)
    }

    const handleModalClose = () => {
        setShowModalItems(false)
        setShowModalContact(false)
        setSelectedInstitution([])
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findInstutuionByParam(initialList, value))
    }

    return (
        <>
            {isUser(role) ? [] :
                <>
                    <Box mt={6} mx={4}>
                        <CustomHeading findParam={handleFindParam} showSearch={true}/>
                    </Box>
                </>
            }
            <Box>
                <VStack mx={5} my={5} display={'block'} mb={24}>
                    {categories?.map((cat: string, idx) => (
                        <Stack key={idx}>
                            {
                                isUser(role) ? [] :
                                    <>
                                        <Skeleton isLoaded={!isLoading} borderRadius={'xl'}>
                                            <Heading size={'lg'} alignSelf={'start'}>{cat}</Heading>
                                        </Skeleton>
                                        <Divider/>
                                    </>

                            }
                            <Wrap py={4} spacing={3}>
                                {list?.map((inst: Institution) => (
                                    inst.Category !== cat ? [] :
                                        isUser(role) && inst.Email !== username && isApp(pathname) ? [] :
                                            <Skeleton key={inst.ID} isLoaded={!isLoading} borderRadius={'xl'}>
                                                <Fade in={true} transition={{enter: {duration: 0.5}}}>
                                                    <Card maxW='sm' borderRadius={'xl'}>
                                                        <CardBody>
                                                            <Image
                                                                src={inst.Logo ? inst.Logo : fallBackImage}
                                                                alt={inst.Name + ' logo'}
                                                                borderRadius='lg'
                                                                fallbackSrc={fallBackImage}
                                                            />
                                                            <Stack mt='6' spacing='3'>
                                                                <Heading size='md'>{inst.Name}</Heading>
                                                                <Text>
                                                                    {inst.Description}
                                                                </Text>
                                                            </Stack>
                                                        </CardBody>
                                                        <Divider color={'lightgray'}/>
                                                        <CardFooter>
                                                            <VStack w={'100%'}>

                                                                <HStack w={'100%'} justify={'space-between'}>


                                                                    <Button w={'100%'} variant='solid'
                                                                            colorScheme='blue'
                                                                            leftIcon={<FontAwesomeIcon
                                                                                icon={faHandHoldingHeart}
                                                                                beat={true}/>}
                                                                            onClick={() => handleModalItems(inst)}>
                                                                        Donar
                                                                    </Button>

                                                                    <Button w={'100%'} variant='solid'
                                                                            colorScheme='green'
                                                                            leftIcon={<FontAwesomeIcon
                                                                                icon={faHandshake}
                                                                                beat={true}/>}
                                                                            onClick={() => handleModalContact(inst)}>
                                                                        Colaborar
                                                                    </Button>

                                                                </HStack>
                                                                {isApp(pathname) ?
                                                                    <>
                                                                        <Divider/>
                                                                        <HStack w={'100%'} justify={'space-between'}>
                                                                            <LinkBox w={'100%'}>
                                                                                <Button w={'100%'} colorScheme={'teal'}
                                                                                        aria-label={`${inst.ID}`}
                                                                                        leftIcon={<FontAwesomeIcon
                                                                                            icon={faList}
                                                                                            beat={true}/>}>
                                                                                    Items
                                                                                </Button>
                                                                                <LinkOverlay
                                                                                    href={`/app/instituciones/${inst.ID}/items`}/>
                                                                            </LinkBox>
                                                                            <LinkBox w={'100%'}>
                                                                                <Button w={'100%'}
                                                                                        colorScheme={'yellow'}
                                                                                        aria-label={`${inst.ID}`}
                                                                                        leftIcon={<FontAwesomeIcon
                                                                                            icon={faPenAlt}
                                                                                            beat={true}/>}>
                                                                                    Editar
                                                                                </Button>
                                                                                <LinkOverlay
                                                                                    href={`/app/instituciones/editar/${inst.ID}`}/>
                                                                            </LinkBox>

                                                                        </HStack>
                                                                    </>
                                                                    : []
                                                                }
                                                            </VStack>
                                                        </CardFooter>
                                                    </Card>
                                                </Fade>
                                            </Skeleton>
                                ))}
                            </Wrap>
                        </Stack>
                    ))}
                </VStack>
            </Box>
            <ModalItems isOpen={showModalItems} onClose={handleModalClose} institution={selectedInstitution}/>
            <ModalContact isOpen={showModalContact} onClose={handleModalClose} institution={selectedInstitution}/>
        </>
    )
        ;
}

