import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Box, Heading, HStack, Icon, IconButton, Show, StackProps, Text} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../store/slice";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBuilding, faHome, faUser, faRightFromBracket, faShop} from '@fortawesome/free-solid-svg-icons'
import * as React from "react";
import {ColorModeSwitcher} from "../ColorModeSwitcher";
import {isApp} from "../helpers/common";
import {CustomMenu} from "./CustomMenu";
import {isUser} from "../store/auth/auth";
import {Logo} from "./Logo";

export const Navbar = () => {
    const {webName} = useSelector((state: any) => state.store)
    const {pathname} = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {role} = useSelector((state: any) => state.store)

    const navStyle: StackProps = {
        position: 'sticky', top: '0', zIndex: 'docked', color: 'black',
        bg: 'gray.100', h: '58', p: '5', boxShadow: 'md', px: '1',
    }

    const handelLogout = () => {
        dispatch(logout())
        navigate("/app/login", {replace: true})
    }

    return (
        <>
            {!isApp(pathname) ? <>
                    <HStack {...navStyle}>
                        <Logo boxSize={'50'}/>
                        <HStack w={'100%'} justify={'space-between'}>
                            <Heading size={'lg'}>{webName}</Heading>
                            <ColorModeSwitcher justifySelf="flex-end"/>
                        </HStack>
                    </HStack>
                </> :
                <>
                    <Show below={'xl'}>
                        <CustomMenu/>
                    </Show>
                    <Show above='xl'>
                        {isApp(pathname) ? <>
                            <HStack {...navStyle}>
                                <Logo boxSize={'50'}/>
                                <HStack w={'100%'} justify={'space-between'}>
                                    <Heading size={'lg'}>{webName}</Heading>

                                    <HStack spacing={6} fontSize={22}
                                            color={'gray'}
                                            justify={'start'}
                                            w={'100%'}
                                            ms={'10'}>

                                        {isUser(role) ?
                                            <NavLink
                                                to={"/app/inicio"}
                                                style={pathname.includes('/app/inicio') ||
                                                pathname.includes('/app/instituciones') ? {color: 'black'} : {}}>
                                                <HStack>
                                                    <Icon fontSize={20} mt={1}>{<FontAwesomeIcon icon={faHome}/>}</Icon>
                                                    <Text mt={1}>Mi institución</Text>
                                                </HStack>
                                            </NavLink>
                                            :
                                            <>
                                                <NavLink
                                                    to={"/app/inicio"}
                                                    style={({isActive}) => isActive ? {color: 'black'} : {}}>
                                                    <HStack>
                                                        <Icon fontSize={20} mt={1}>{<FontAwesomeIcon
                                                            icon={faHome}/>}</Icon>
                                                        <Text mt={1}>Inicio</Text>
                                                    </HStack>
                                                </NavLink>
                                                <NavLink
                                                    to={"/app/instituciones"}
                                                    style={({isActive}) => isActive ? {color: 'black'} : {}}>
                                                    <HStack>
                                                        <Icon fontSize={20} mt={1}>{<FontAwesomeIcon
                                                            icon={faShop}/>}</Icon>
                                                        <Text mt={1}>Instituciones</Text>
                                                    </HStack>
                                                </NavLink>
                                                <NavLink
                                                    to={"/app/usuarios"}
                                                    style={({isActive}) => isActive ? {color: 'black'} : {}}>
                                                    <HStack>
                                                        <Icon fontSize={20} mt={1}>{<FontAwesomeIcon
                                                            icon={faUser}/>}</Icon>
                                                        <Text mt={1}>Usuarios</Text>
                                                    </HStack>
                                                </NavLink>
                                            </>
                                        }

                                    </HStack>
                                    <HStack>
                                        <Text color={'black'} fontSize={12}>v{process.env.REACT_APP_VERSION}</Text>
                                        <ColorModeSwitcher justifySelf="flex-end"/>
                                        <NavLink
                                            to={"/app/login"}>
                                            <IconButton aria-label={'logout'}
                                                        onClick={handelLogout}
                                                        color={'red'}
                                                        variant={'link'}
                                                        mt={'1'}
                                            >
                                                <FontAwesomeIcon icon={faRightFromBracket} fontSize={20}/>
                                            </IconButton>
                                        </NavLink>
                                    </HStack>
                                </HStack>
                            </HStack>
                        </> : []
                        }
                    </Show>
                </>
            }


        </>
    );
}