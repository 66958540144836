export const findByParam = (data = [] as any, param = '') => {
    if (param.length === 0) {
        return data
    }

    param = param.toLowerCase()

    let newList = [] as any

    for (let i = 0; i < data.length; i++) {
        const obj = {
            Name: data[i].Name?.includes('.com') ? data[i].Name?.substring(0,data[i].Name.length-4) : data[i].Name,
            Phone: data[i].Phone,
            Address: data[i].Address,
            Neighborhood: data[i].Neighborhood,
            Institution: data[i].Institution,
            Category: data[i].Category,
            Email: data[i].Email?.substring(0,data[i].Email.length-4),
            Referents: data[i].Referents,
        }

        const values = Object.values(obj)
        if (JSON.stringify(values).toLowerCase().includes(param)) {
            newList = [
                ...newList,
                data[i]
            ]
        }
    }

    return newList
}