import {useEffect, useState} from "react";
import {capitalize} from "../helpers/capitalize";
/*import {fakePassword} from "../pages/usuarios/pages/FormUsuario";*/
import {format} from "date-fns";

export const useForm = (formValue: any) => {
    const [form, setFormState] = useState(formValue);
    const [errors, setErrors] = useState([])

    const onInputChange = ({target}: any) => {
        let {name, value} = target;

        if ((name === 'WhatsApp')) {
            const {checked} = target
            setFormState({
                ...form,
                [name]: checked
            })
            return
        }

        if (name === 'Deleted') {
            const {checked} = target
            if (checked) {
                setFormState({
                    ...form,
                    [name]: checked,
                    DeletedAt: format(new Date(), 'yyyy-MM-dd'),
                })
                return
            }

            setFormState({
                ...form,
                [name]: checked,
                DeletedAt: null,
            })

            return
        }

        if (name === 'Email') {
            setFormState({
                ...form,
                Email: value,
            })
            return
        }


        if (name.includes('Referents')) {
            const id = name.split("[")[1].split("].")[0]
            const inputName = name.split("[")[1].split("].")[1]

            let newReferents: any = []
            for (const referent of form.Referents) {
                let idx = form.Referents.indexOf(referent);
                if (idx === Number(id)) {
                    if (inputName === 'Phone') {
                        value = Number(value)
                    }

                    if ((inputName === 'WhatsApp')) {
                        const {checked} = target
                        newReferents = [
                            ...newReferents,
                            {
                                ...referent,
                                [inputName]: checked
                            }
                        ]
                        continue
                    }

                    newReferents = [
                        ...newReferents,
                        {
                            ...referent,
                            [inputName]: value
                        }
                    ]
                    continue
                }
                newReferents = [...newReferents, referent]
            }

            setFormState({
                ...form,
                Referents: newReferents,
            })

            return
        }

        if (name === 'Category') {
            setFormState({
                ...form,
                [name]: value
            })
            return
        }

        setFormState({
            ...form,
            [name]: value
        })

    }


    useEffect(() => {
        if (form.validations) {
            let valid = true;
            const newErrors: any = {};

            for (const key in form.validations) {
                const value = form[key];
                const validation = form.validations[key];
                if (validation?.required?.value && !value) {
                    valid = false;
                    newErrors[key] = validation?.required?.message;
                    continue
                }

                const pattern = validation?.pattern;
                if (validation?.pattern?.value && !RegExp(pattern.value).test(value)) {
                    valid = false;
                    newErrors[key] = pattern.message;
                    continue
                }

                const custom = validation?.custom;
                if (value && custom?.isValid && !custom.isValid(value.toString())) {
                    valid = false;
                    newErrors[key] = custom.message;
                }
            }

            setErrors(newErrors);
            if (valid) {
                setErrors([]);
            }
        }
    }, [form]);


    return {
        ...form,
        form,
        onInputChange,
        setFormState,
        errors
    }
}