import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Box, Button, Center, Heading, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../store/slice";
import {isApp} from "../helpers/common";
import {ColorModeSwitcher} from "../ColorModeSwitcher";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsStaggered, faHome, faRightFromBracket, faShop, faUser, faXmark} from "@fortawesome/free-solid-svg-icons";
import {isUser} from "../store/auth/auth";
import {Logo} from "./Logo";

export const CustomMenu = () => {
    const {webName} = useSelector((state: any) => state.store)
    const {pathname} = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {role} = useSelector((state: any) => state.store)
    const handelLogout = () => {
        dispatch(logout())
        navigate("/app/login", {replace: true})
    }

    return (
        <Menu autoSelect={false}>
            {({isOpen}: any) => (
                <>
                    <MenuButton
                        position={'sticky'}
                        top={'0'}
                        zIndex={'docked'}
                        bg={'gray.100'}
                        p={8}
                        borderRadius={15}
                        borderTopEndRadius={0}
                        borderTopStartRadius={0}
                        borderBottomStartRadius={0}
                        color={'black'}
                        fontSize={26}
                        boxShadow={'md'}
                        _hover={{bg: 'gray.100'}}
                        _expanded={{bg: 'gray.100'}}
                        isActive={isOpen} as={Button}>
                        {isOpen ?
                            <Center w={'7'}>
                                <FontAwesomeIcon icon={faXmark}/>
                            </Center>
                            :
                            <Center w={'7'}>
                                <FontAwesomeIcon icon={faBarsStaggered}/>
                            </Center>
                        }
                    </MenuButton>
                    <MenuList p={4}
                              bg={'gray.100'}
                              boxShadow={'md'}
                              borderRadius={15}
                              borderTopStartRadius={0}
                              borderBottomStartRadius={0}
                              color={'grey'}
                              fontSize={22}
                              borderWidth='0px'
                    >

                        <HStack>
                            <Logo boxSize={'50'}/>
                            <HStack>
                                <Heading color={'black'} size={'lg'}>{webName}</Heading>
                            </HStack>
                        </HStack>
                        {isApp(pathname) ? <>
                            {isUser(role) ?
                                <MenuItem bg={'gray.100'} onClick={() => navigate('/app/inicio')}
                                          _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                    <NavLink
                                        to={"/app/inicio"}
                                        style={pathname.includes('/app/inicio') ||
                                        pathname.includes('/app/instituciones') ? {color: 'black'} : {}}>
                                        <HStack>
                                            <Icon fontSize={20} mt={1}>{<FontAwesomeIcon icon={faHome}/>}</Icon>
                                            <Text mt={1}>Mi institución</Text>
                                        </HStack>
                                    </NavLink>
                                </MenuItem>
                                :
                                <>
                                    <MenuItem bg={'gray.100'} onClick={() => navigate('/app/inicio')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"/app/inicio"}
                                            style={({isActive}) => isActive ? {color: 'black'} : {}}>
                                            <HStack>
                                                <Icon fontSize={20} mt={1}>{<FontAwesomeIcon icon={faHome}/>}</Icon>
                                                <Text mt={1}>Inicio</Text>
                                            </HStack>
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem bg={'gray.100'} onClick={() => navigate('/app/instituciones')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"/app/instituciones"}
                                            style={({isActive}) => isActive ? {color: 'black'} : {}}>
                                            <HStack>
                                                <Icon fontSize={20} mt={1}>{<FontAwesomeIcon icon={faShop}/>}</Icon>
                                                <Text mt={1}>Instituciones</Text>
                                            </HStack>
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem bg={'gray.100'} onClick={() => navigate('/app/usuarios')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"/app/usuarios"}
                                            style={({isActive}) => isActive ? {color: 'black'} : {}}>
                                            <HStack>
                                                <Icon fontSize={20} mt={1}>{<FontAwesomeIcon icon={faUser}/>}</Icon>
                                                <Text mt={1}>Usuarios</Text>
                                            </HStack>
                                        </NavLink>
                                    </MenuItem>
                                </>
                            }
                        </> : []
                        }
                        <MenuItem bg={'gray.100'} ms={0}
                                  _focus={{bg: 'gray.700', borderRadius: '10px'}}
                                  onClick={handelLogout}>
                            <NavLink

                                to={"/auth/login"}>
                                <Icon aria-label={'logout'}
                                      color={'red'}
                                >
                                    <FontAwesomeIcon icon={faRightFromBracket} fontSize={20}/>
                                </Icon>
                            </NavLink>
                        </MenuItem>

                        <Box bg={'gray.100'} ps={0}>
                            <ColorModeSwitcher color={'black'} ms={0} mb={2}/>
                            <Text color={'black'} px={3} fontSize={12}>v{process.env.REACT_APP_VERSION}</Text>
                        </Box>
                    </MenuList>
                </>
            )}
        </Menu>
    );
}
