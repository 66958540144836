import {isAfter, isBefore, parse} from "date-fns";

export const welcome = () => {

    const now = parse(new Date().getHours() + ":" + new Date().getMinutes(), 'HH:mm', new Date())

    const noon = parse('12:00', 'HH:mm', new Date())
    const afternoonEnd = parse('20:00', 'HH:mm', new Date())
    const midNight = parse('00:00', 'HH:mm', new Date())
    const eveningEnd = parse('04:00', 'HH:mm', new Date())

    if (isAfter(now, noon) && isBefore(now, afternoonEnd)) {
        return BUENAS_TARDES
    } else if (isAfter(now, afternoonEnd) && isBefore(now, midNight)) {
        return BUENAS_NOCHES
    } else if (isAfter(now, midNight) && isBefore(now, eveningEnd)) {
        return BUENAS_NOCHES
    } else {
        return BUENOS_DIAS
    }
}

const BUENOS_DIAS = 'Buenos días...'
const BUENAS_TARDES = 'Buenas tardes...'
const BUENAS_NOCHES = 'Buenas noches...'