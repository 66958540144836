
import {Button} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {faMoon, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";

export const AddButton = ({pathTo}: any) => {
    return (
        <Link to={pathTo}>
            <Button leftIcon={<FontAwesomeIcon icon={faPlus}/>} colorScheme={'blue'}>
                Agregar
            </Button>
        </Link>
    )
}