import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    AspectRatio,
    Badge,
    Box,
    Button,
    Center,
    Divider,
    Heading,
    HStack, Icon,
    LinkBox,
    LinkOverlay,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, useColorModeValue,
    VStack, Wrap
} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleCheck, faCopy, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {Referent} from "../interfaces/interfaces";
import Clipboard from "@react-native-clipboard/clipboard";
import {useEffect, useState} from "react";
import * as React from "react";

export const ModalContact = ({isOpen = false, onClose = [] as any, institution = [] as any}) => {
    const [width, setWidth] = useState(window.innerWidth);
    const referentBGColor = useColorModeValue('gray.100', 'gray.800')
    const accordionBGColor = useColorModeValue('gray.100', 'gray.800')
    const accordionTextColor = useColorModeValue('gray.900', 'gray.100')

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [isCBUCopied, setIsCBUCopied] = useState(false)
    const [isAliasCopied, setIsAliasCopied] = useState(false)

    const formattedAddress = institution.Address ? institution.Address
        .replaceAll(' ', '+') + ',+' + institution.City + ',+Tierra+del+Fuego'
        : 'Ushuaia,+Tierra+del+Fuego'

    const handleCopyCBU = (value: string) => {
        Clipboard.setString(value)
        setIsCBUCopied(true)
        setTimeout(() => setIsCBUCopied(false), 1000)
    }

    const handleCopyAlias = (value: string) => {
        Clipboard.setString(value)
        setIsAliasCopied(true)
        setTimeout(() => setIsAliasCopied(false), 1000)
    }

    return (
        <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                scrollBehavior={'inside'}
                size={isMobile ? 'sm' : 'lg'}
            >
                <ModalOverlay/>
                <ModalContent mt={20} maxW={isMobile ? '95%' : 'lg'}>
                    <ModalHeader>{institution.Name}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Heading size={'md'}>
                            ¿Cómo colaborar?
                        </Heading>
                        <Text my={4}>

                        </Text>
                        <Accordion>
                            {institution.Referents && institution.Referents.length !== 0 ?

                                <AccordionItem>
                                    <Heading>
                                        <AccordionButton _expanded={{
                                            minHeight: 54,
                                            borderBottomRadius: 10,
                                            bg: accordionBGColor,
                                            color: accordionTextColor
                                        }}>
                                            <Box as="span" flex='1' textAlign='left'>
                                                <HStack>
                                                    <FontAwesomeIcon icon={faCircleCheck} color={'green'}
                                                                     fontSize={20}/>
                                                    <Text>
                                                        Podes comunicarte
                                                        con algún referente
                                                        de la institución.
                                                    </Text>
                                                </HStack>
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </Heading>
                                    <AccordionPanel pb={0}>
                                        {institution.Referents ?
                                            institution.Referents.map((ref: Referent, index: number) => (
                                                <Box key={index}>
                                                    <VStack justify={'left'} bg={referentBGColor} p={3} w={'100%'} mb={2}
                                                            borderRadius={10}>
                                                        <HStack w={'100%'}>
                                                            <Text fontSize={isMobile ? '14' : '16'}
                                                                  fontWeight={'bold'}>{ref.Name}</Text>
                                                        </HStack>
                                                        <Wrap justify={'left'} w={'100%'}>
                                                            {ref.Phone ?
                                                                <>
                                                                    {ref.WhatsApp ?
                                                                        <LinkBox>
                                                                            <Badge p={'1'} px={'3'} borderRadius={'5'}
                                                                                   colorScheme={'green'}>
                                                                                <HStack>
                                                                                    <FontAwesomeIcon icon={faWhatsapp}
                                                                                                     beat={true}/>
                                                                                    <Text
                                                                                        fontSize={isMobile ? '14' : '16'}>
                                                                                        {ref.Phone}
                                                                                    </Text>
                                                                                </HStack>

                                                                            </Badge>
                                                                            <LinkOverlay
                                                                                href={'https://wa.me/+54' + ref.Phone}
                                                                                target={'_blank'}/>
                                                                        </LinkBox>
                                                                        : []}
                                                                    <LinkBox>
                                                                        <Badge p={'1'} px={'3'} borderRadius={'5'}
                                                                               colorScheme={'red'}>
                                                                            <HStack>
                                                                                <FontAwesomeIcon icon={faPhone}
                                                                                                 beat={true}/>
                                                                                <Text fontSize={isMobile ? '14' : '16'}>
                                                                                    {ref.Phone}

                                                                                </Text>
                                                                            </HStack>
                                                                        </Badge>
                                                                        <LinkOverlay href={'tel:+54' + ref.Phone}/>
                                                                    </LinkBox>
                                                                </>
                                                                : []}
                                                        </Wrap>
                                                    </VStack>

                                                </Box>
                                            )) : []
                                        }
                                    </AccordionPanel>
                                </AccordionItem>
                                : []
                            }
                            <AccordionItem>
                                <Heading>
                                    <AccordionButton _expanded={{
                                        minHeight: 54,
                                        borderBottomRadius: 10,
                                        bg: accordionBGColor,
                                        color: accordionTextColor
                                    }}>
                                        <Box as="span" flex='1' textAlign='left'>
                                            <HStack>
                                                <FontAwesomeIcon icon={faCircleCheck} color={'green'} fontSize={20}/>
                                                <Text>
                                                    Podés acercarte al establecimiento para saber en qué se puede
                                                    ayudar.
                                                </Text>
                                            </HStack>
                                        </Box>
                                        <AccordionIcon/>
                                    </AccordionButton>
                                </Heading>
                                <AccordionPanel pb={4}>
                                    <AspectRatio ratio={16 / 9} borderRadius={10}>
                                        <iframe
                                            src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyAwH9n8Yujc0-JyAEhFEtLg5gh5PBBSj2w&q=' + formattedAddress}
                                        />
                                    </AspectRatio>
                                </AccordionPanel>
                            </AccordionItem>
                            {
                                institution.CBU === '' && institution.AccountAlias === '' ?
                                    []
                                    :
                                    <AccordionItem>
                                        <Heading>
                                            <AccordionButton minHeight={54} _expanded={{
                                                minHeight: 54,
                                                borderBottomRadius: 10,
                                                bg: accordionBGColor,
                                                color: accordionTextColor
                                            }}>
                                                <Box as="span" flex='1' textAlign='left'>
                                                    <HStack>
                                                        <FontAwesomeIcon icon={faCircleCheck} color={'green'}
                                                                         fontSize={20}/>
                                                        <Text>
                                                            Podés hacer una transferencia.
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                                <AccordionIcon/>
                                            </AccordionButton>
                                        </Heading>
                                        <AccordionPanel pb={4}>

                                            <Center>
                                                <VStack>

                                                    {institution.CBU !== '' ?
                                                        <>
                                                            <Badge p={2} px={3} borderRadius={'5'}
                                                                   colorScheme={'green'}
                                                                   fontSize={isMobile ? '14' : '18'}
                                                                   as={'button'}
                                                                   onClick={() => handleCopyCBU(institution.CBU)}
                                                            >
                                                                CBU: {institution.CBU}
                                                                <Box display={'inline'} ms={3}>
                                                                    {isCBUCopied ? <FontAwesomeIcon
                                                                            icon={faCheck}
                                                                        /> :
                                                                        <FontAwesomeIcon
                                                                            icon={faCopy}
                                                                            beat={true}
                                                                        />}

                                                                </Box>
                                                            </Badge>
                                                        </>
                                                        : []}
                                                    {institution.AccountAlias !== '' ?
                                                        <Badge p={2} px={3} borderRadius={'5'}
                                                               colorScheme={'purple'} fontSize={isMobile ? '14' : '18'}
                                                               as={'button'}
                                                               onClick={() => handleCopyAlias(institution.AccountAlias)}
                                                        >
                                                            ALIAS: {institution.AccountAlias}
                                                            <Box display={'inline'} ms={3}>
                                                                {isAliasCopied ? <FontAwesomeIcon
                                                                        icon={faCheck}
                                                                    /> :
                                                                    <FontAwesomeIcon
                                                                        icon={faCopy}
                                                                        beat={true}
                                                                    />}

                                                            </Box>
                                                        </Badge>
                                                        : []}
                                                </VStack>
                                            </Center>
                                        </AccordionPanel>
                                    </AccordionItem>
                            }
                        </Accordion>

                    </ModalBody>
                    <ModalFooter>
                       {/* <Button onClick={onClose}>Close</Button>*/}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}