import {createSlice} from "@reduxjs/toolkit";

export const slice = createSlice({
    name: 'store',
    initialState: {
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
        role: localStorage.getItem("role"),
        webName: "CompartiendoBienestar",
    },
    reducers: {
        login: (state, action) => {
            state.username = action.payload.username
            state.token = action.payload.token
            state.role = action.payload.role

            localStorage.setItem("token", action.payload.token)
            localStorage.setItem("username", action.payload.username)
            localStorage.setItem("role", action.payload.role)
        },
        logout: (state) => {
            state.username = null
            state.token = null
            state.role = null

            localStorage.clear()
        }
    }
})


export const {login, logout} = slice.actions