import {Navigate, Route, Routes} from "react-router-dom";
import {Dashboard} from "../pages/app/Dashboard";
import {useSelector} from "react-redux";
import {isUser} from "../store/auth/auth";
import {Web} from "../pages/Web";
import {Institutions} from "../pages/app/Institutions";
import {Users} from "../pages/app/Users";
import {Login} from "../pages/app/login/Login";
import {FormInstitution} from "../pages/app/FormInstitution";
import {Items} from "../pages/app/Items";

export const Router = () => {
    const {role} = useSelector((state: any) => state.store)
    return (
        <Routes>
            <Route path={'/'} element={<Web/>}/>
            <Route path={'/app/*'} element={
                <Routes>
                    <Route path={'/login'} element={<Login/>}/>
                    <Route path={'/inicio'} element={<Dashboard/>}/>
                    {
                        isUser(role) ?
                            <>
                                <Route path={'/instituciones/editar/:id'} element={<FormInstitution/>}/>
                                <Route path={'/instituciones/:id/items'} element={<Items/>}/>
                            </>
                            :
                            <>
                                <Route path={'/instituciones'} element={<Institutions/>}/>
                                <Route path={'/instituciones/*'} element={
                                    <Routes>
                                        {
                                            isUser(role) ? [] :
                                                <Route path={'/nueva'} element={<FormInstitution/>}/>
                                        }
                                        <Route path={'/editar/:id'} element={<FormInstitution/>}/>
                                        <Route path={'/:id/items'} element={<Items/>}/>
                                    </Routes>
                                }/>
                            </>
                    }

                    {
                        isUser(role) ? [] :
                            <Route path={'/usuarios'} element={<Users/>}/>
                    }
                    <Route path={'*'} element={<Navigate to={'inicio'}/>}></Route>
                </Routes>
            }/>
            <Route path={'*'} element={<Navigate to={'/'}/>}></Route>
        </Routes>
    );
}