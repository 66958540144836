import {
    Badge,
    Button,
    Collapse,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    Skeleton,
    VStack,
} from "@chakra-ui/react";
import {User} from "../interfaces/interfaces";
import * as React from "react";
import {useEffect, useState} from "react";
import {SaveButton} from "./SaveButton";
import {useForm} from "../hooks/useForm";
import {format, formatISO, parse, parseISO} from "date-fns";
import {randomPassword} from "../utils/password";
import {api} from "../api/api";
import {errorCodeUserAlreadyRegistered} from "../utils/constants";
import {useCustomToast} from "../hooks/useCustomToast";
import {userValidations} from "../helpers/userValidations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCopy, faKey} from "@fortawesome/free-solid-svg-icons";
import Clipboard from '@react-native-clipboard/clipboard';
import {isUser} from "../store/auth/auth";

export const DrawerUser = ({isOpen = false, onClose = [] as any, user = {} as User, onSubmitComplete = [] as any}) => {
    const today = format(new Date(), 'yyyy-MM-dd')
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [isPasswordCopied, setIsPasswordCopied] = useState(false)
    const toast = useCustomToast({})

    const {form, onInputChange, setFormState, errors} = useForm({
        userValidations,
        CreatedAt: today
    })

    useEffect(() => {
        if (!user.ID) {
            setShowPassword(true)
            setFormState({
                Role: 'Admin', Password: randomPassword(13), userValidations,
                CreatedAt: today
            })
        }

    }, [isOpen]);

    useEffect(() => {
        setFormState({
            userValidations,
            CreatedAt: today
        })

        if (user.ID) {
            user.CreatedAt = format(parseISO(user.CreatedAt), 'yyyy-MM-dd')
            user.DeletedAt = user.DeletedAt ? format(parseISO(user?.DeletedAt!), 'yyyy-MM-dd') : null
            setFormState({...user, userValidations})
            setShowPassword(false)
        }

    }, [user]);

    const copyPassword = () => {
        Clipboard.setString(form.Password)
        setIsPasswordCopied(true)
        setTimeout(() => setIsPasswordCopied(false), 1000)
    }

    const handleGenerateNewPassword = () => {
        setShowPassword(true)
        setFormState({...form, Password: randomPassword(13)})
    }

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        if (errors.length !== 0) {
            console.log(errors)
            return
        }
        setIsSaving(true)

        let status = 'guardado'
        if (form.ID) {
            status = 'actualizado'
        }

        let aux: User = {
            ID: form.ID,
            Name: form.Name,
            Password: showPassword ? form.Password : randomPassword(13),
            Role: form.Role,
            InstitutionID: form.InstitutionID,
            InstitutionName: form.InstitutionName,
            CreatedAt: formatISO(parse(form.CreatedAt, 'yyyy-MM-dd', new Date())),
            UpdatedAt: form.UpdatedAt,
            DeletedAt: form.DeletedAt,
            Deleted: form.Deleted
        }

        try {
            if (!form.ID) {
                await api.post(`/users/save`, aux)

            } else {
                await api.put(`/users/update`, aux)
            }
            toast({
                    id: crypto.randomUUID(),
                    title: `Usuario ${status} correctamente!`,
                    status: 'success'
                }
            )


        } catch (error: any) {
            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeUserAlreadyRegistered) {
                setIsSaving(false)
                toast({
                    title: `Ya existe el nombre de usuario '${form.Name}'!`,
                    status: 'error'
                })

                return
            }

            setIsSaving(false)
            toast({title: `El usuario no pudo ser ${status}!`, status: 'error'})

            return
        }

        onClose()
        setIsSaving(false)
        setShowPassword(false)
        onSubmitComplete()

    }


    return (
        <>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={() => {
                    setShowPassword(false)
                    onClose()
                }}
            >
                <DrawerOverlay/>
                <DrawerContent>
                    <DrawerCloseButton/>
                    <DrawerHeader>
                        {form.Name ?
                            <Badge fontSize={22} px={3} py={1} colorScheme={'green'} variant={'solid'} borderRadius={5}>
                                {form.Name}
                            </Badge>
                            : []
                        }
                    </DrawerHeader>

                    <DrawerBody>
                        <Heading size={'md'}>
                            {form.ID ?
                                'Editar usuario'
                                :
                                'Nuevo usuario'
                            }
                        </Heading>
                        <Divider my={6}/>
                        <Skeleton isLoaded={true} borderRadius={'xl'}>
                            <form autoComplete={'off'} id={user.ID ? user.ID : crypto.randomUUID()}>
                                <FormControl isInvalid={submitted && form.Name === ''}
                                             isRequired>
                                    <FormLabel>Nombre de usuario</FormLabel>
                                    <Input
                                        disabled={isUser(user.Role)}
                                        name={'Name'}
                                        placeholder={"Ingrese un nombre"}
                                        type='text'
                                        value={form.Name || []}
                                        onChange={onInputChange}
                                    />
                                    {submitted && errors.Name ? (
                                        <FormErrorMessage>{errors.Name}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>

                                <Collapse in={showPassword} animateOpacity>
                                    {showPassword ?
                                        <FormControl mt={2}>
                                            <FormLabel>Nueva contraseña</FormLabel>
                                            <Button
                                                fontSize={18}
                                                onClick={copyPassword}
                                                variant={'solid'}
                                                colorScheme={'blue'}
                                                value={form.Password || []}
                                                aria-label={'copy-password'}
                                                rightIcon={isPasswordCopied ? <FontAwesomeIcon
                                                        icon={faCheck}
                                                    /> :
                                                    <FontAwesomeIcon
                                                        icon={faCopy}
                                                        beat={true}
                                                    />}>
                                                {form.Password}
                                            </Button>
                                        </FormControl>
                                        : []
                                    }
                                </Collapse>
                                <FormControl mt={2} isInvalid={submitted && form.Role === ''}>
                                    <FormLabel>Rol</FormLabel>
                                    <Input
                                        disabled={true}
                                        name={'Role'}
                                        type='text'
                                        value={form.Role || []}
                                        onChange={onInputChange}
                                    />
                                    {submitted && errors.Role === '' ? (
                                        <FormErrorMessage>Debe ingresar un rol</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                {form.InstitutionName ?
                                    <FormControl mt={2}>
                                        <FormLabel>Nombre de institución</FormLabel>
                                        <Input
                                            disabled={true}
                                            name={'InstitutionName'}
                                            type='text'
                                            value={form.InstitutionName}
                                        />
                                    </FormControl>
                                    : []
                                }
                                <FormControl mt={2}>
                                    <FormLabel>Fecha de alta</FormLabel>
                                    <Input
                                        disabled={true}
                                        name={'CreatedAt'}
                                        type='date'
                                        value={form.CreatedAt}
                                        onChange={onInputChange}
                                    />
                                </FormControl>
                            </form>
                        </Skeleton>
                    </DrawerBody>

                    <DrawerFooter>
                        <HStack w={'100%'}>
                            <VStack w={'100%'}>
                                {user.ID ?
                                    <HStack w={'100%'} mb={-4}>
                                        <Button w={'100%'}
                                                onClick={handleGenerateNewPassword} colorScheme={'teal'}
                                                leftIcon={<FontAwesomeIcon icon={faKey} beat={true}/>}>
                                            Generar nueva contraseña
                                        </Button>
                                    </HStack>
                                    : []
                                }
                                <HStack w={'100%'} justify={'space-between'}>

                                    <Button w={'100%'} onClick={() => {
                                        setShowPassword(false)
                                        onClose()
                                    }}>Cerrar</Button>
                                    <SaveButton w={'100%'} isLoading={isSaving} onSubmit={onSubmit}/>
                                </HStack>
                            </VStack>
                        </HStack>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}