import * as React from "react"
import {ChakraProvider, extendTheme, ThemeConfig} from "@chakra-ui/react"

import {es} from 'date-fns/locale'
import {setDefaultOptions} from "date-fns";
import {Router} from "./router/Router";

setDefaultOptions({locale: es})

const config: ThemeConfig = {
    initialColorMode: 'system',
    useSystemColorMode: true,
}

// 3. extend the theme
const theme = extendTheme({ config })

export const App = () => {
    return (
        <ChakraProvider theme={theme}>
           <Router/>
        </ChakraProvider>
    )
}


