import {Navbar} from "../../components/Navbar";
import {DeleteConfirm} from "../../components/DeleteConfirm";
import {
    Badge, Container, Fade,
    HStack,
    IconButton,
    list,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead, Tooltip,
    Tr,
    useDisclosure, VStack, Wrap
} from "@chakra-ui/react";
import {TableSkeleton} from "../../components/TableSkeleton";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faPenAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";
import {deleteObjectByID} from "../../helpers/deleteObjectByID";
import {useFetch} from "../../hooks/useFetch";
import {useCustomToast} from "../../hooks/useCustomToast";
import {Institution, Referent} from "../../interfaces/interfaces";
import {CustomHeading} from "../../components/CustomHeading";
import {findByParam} from "../../helpers/findByParam";
import {api} from "../../api/api";
import {useSelector} from "react-redux";
import {isUser} from "../../store/auth/auth";
import * as React from "react";

export const Institutions = () => {
    const {data, isLoading, setIsLoading} = useFetch("instituciones", "institutions")
    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)
    const toast = useCustomToast({})
    const cancelRef: any = useRef()
    const [infoToDelete, setInfoToDelete] = useState({id: '' as any, description: ''})
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [showDeleted, setShowDeleted] = useState(false)
    const handleDeleteInstitution = async (id: string) => {

        try {
            await deleteObjectByID("institutions", id)

            setList(list.filter((value: Institution) => value.ID !== id))
            // setInitialList(initialList.filter((value: Socio) => value.id !== id))
            toast({title: "Institución eliminada con éxito!", status: "success"})
            onClose()
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            toast({title: "La institución no pudo ser eliminada!", status: "error"})
        }
    }

    const handleShowDeleted = async ({target}: any) => {
        setList([])
        setInitialList([])
        setShowDeleted(false)

        const {checked} = target

        let path = "/institutions?filter=enabled"
        let errMsg = "No se pudo obtener la lista de instituciones!"
        if (checked) {
            path = "/institutions?filter=deleted"
            errMsg = "No se pudo obtener la lista de instituciones dadas de baja!"
            setShowDeleted(true)
        }

        try {
            setIsLoading(true)
            const res = await api.get(path)
            setList(res.data)
            setInitialList(res.data)

        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            toast({title: errMsg, status: 'error'})
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findByParam(initialList, value))
    }

    useEffect(() => {
        setList(data)
        setInitialList(data)
    }, [data]);

    return (
        <>
            <Navbar/>

            <Container mt={8}
                       maxW='100%'>

                <CustomHeading title={'Instituciones'}
                               addBtnTo={'/app/instituciones/nueva'}
                               exportData={list}
                               findParam={handleFindParam}
                               handleShowDeleted={handleShowDeleted}
                               exportFilePath={"/institutions/export_xls"}
                               showSearch={true}
                               showAddBtn={true}
                               showDeleted={true}
                />
                <TableContainer overflowY={'scroll'} maxH={'63vh'}>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                            <Thead>
                                <Tr>
                                    <Th>Nombre</Th>
                                    <Th>Teléfono</Th>
                                    <Th>Direccion</Th>
                                    <Th>Barrio</Th>
                                    <Th>Categoría</Th>
                                    <Th>Correo</Th>
                                    <Th>Referentes</Th>
                                    <Th>Acciones</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {list?.map((institution: Institution) => (
                                    <Tr key={institution.ID}>
                                        <Tooltip label={institution.Name}>
                                            <Td maxWidth={'10rem'} textOverflow={'ellipsis'} overflowX={'hidden'}>
                                                {institution.Name}
                                            </Td>
                                        </Tooltip>
                                        <Td maxWidth={'7rem'} isNumeric>{institution.Phone}</Td>
                                        <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                            maxWidth={'13rem'}>{institution.Address}</Td>
                                        <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                            maxWidth={'13rem'}>{institution.Neighborhood}</Td>
                                        <Td>{institution.Category}</Td>
                                        <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                            maxWidth={'12rem'}>{institution.Email}</Td>
                                        <Td maxWidth={'13rem'}>
                                            <Wrap>
                                                {institution.Referents ?
                                                    institution.Referents.map((ref: Referent, idx: number) => (
                                                        <Badge p={'1'} px={'2'} key={idx} borderRadius={'5'}
                                                               colorScheme={'blue'}>{ref.Name}
                                                        </Badge>
                                                    ))
                                                    : []}
                                            </Wrap>
                                        </Td>
                                        <Td>
                                            {institution.Deleted ? [] :
                                                <Link to={`/app/instituciones/${institution.ID}/items`}>
                                                    <IconButton size={'sm'} mx={1} colorScheme={'teal'}
                                                                aria-label={`compras ${institution.ID}`}
                                                                icon={<FontAwesomeIcon icon={faList}/>}/>
                                                </Link>
                                            }
                                            <Link to={`/app/instituciones/editar/${institution.ID}`}>
                                                <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                            aria-label={`editar ${institution.ID}`}
                                                            icon={<FontAwesomeIcon icon={faPenAlt}/>}/>
                                            </Link>
                                            {institution.Deleted ? [] :
                                                <IconButton size={'sm'} mx={1}
                                                            colorScheme={'red'} aria-label={`borrar ${institution.ID}`}
                                                            icon={<FontAwesomeIcon icon={faTrash}/>}
                                                            onClick={() => {
                                                                onOpen()
                                                                setInfoToDelete({
                                                                    id: institution.ID, description: institution.Name
                                                                })
                                                            }}/>
                                            }
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Fade>
                </TableContainer>

                <TableSkeleton isLoading={isLoading}/>

                <HStack justify={'space-around'} my={4}>
                    <Badge px={3} py={1} borderRadius={5}
                           colorScheme={'purple'}>Instituciones totales: {list ? list.length : 0} </Badge>
                </HStack>
            </Container>

            <DeleteConfirm isOpen={isOpen}
                           onClose={onClose}
                           pron={'la'}
                           entity={'institución'}
                           id={infoToDelete.id}
                           description={infoToDelete.description}
                           onDeleteConfirm={handleDeleteInstitution}
                           cancelRef={cancelRef}
            />
        </>
    )
}