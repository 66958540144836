import {useEffect, useState} from "react";
import {useCustomToast} from "./useCustomToast";
import {api} from "../api/api";

export const useGetObjectByID = (path = '', id = '', pron = 'el', obj = '') => {
    const toast = useCustomToast({})
    const [object, setObject] = useState({})
    const [isObjectLoading, setIsObjectLoading] = useState(true)

    const getData = async () => {
        try {
            const res = await api.get(`/${path}/${id}`)
            setObject(res.data);
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }
            if (!toast.isActive("custom-toast")) {
                toast({title: `No se pudo obtener ${pron} ${obj}`, status:'error'})
            }
            setObject([])
            setIsObjectLoading(false);
            return
        }

        setIsObjectLoading(false);
    }

    useEffect(() => {
        if (id) getData()
    }, []);

    return {
        object,
        isLoading: isObjectLoading,
        setIsLoading: setIsObjectLoading,
    }
}