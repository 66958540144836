import {Button} from "@chakra-ui/react";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const SaveButton = ({isLoading, onSubmit, w}: any) => {
    return (
        <Button w={w}
                isLoading={isLoading}
                onClick={onSubmit}
                m={4} my={6} mr={0}
                type={"button"}
                leftIcon={<FontAwesomeIcon icon={faFloppyDisk}/>}
                colorScheme={'blue'}>
            Guardar
        </Button>
    )
}