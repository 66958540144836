import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Switch,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {AddButton} from "./AddButton";
import {useDownloadFile} from "../hooks/useDownloadFile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faMagnifyingGlass, faPlus} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import {BackButton} from "./BackButton";

export const CustomHeading = ({
                                  title = '',
                                  findParam = [] as any,
                                  addBtnTo = '',
                                  backButtonTo = '',
                                  exportData = [] as any,
                                  exportFilePath = '',
                                  handleShowDeleted = [] as any,
                                  handleOnAddItem = [] as any,
                                  onOpenUserModal = [] as any,
                                  showDeleted = false,
                                  showSearch = false,
                                  showExport = false,
                                  showAddBtn = false,
                                  showBackBtn = false,
                                  showAddItem = false,
                              }) => {

    const {downloadFile, isDownloading} = useDownloadFile({value: exportData})

    return (
        <>
            <Wrap justify={"space-between"}>

                <WrapItem>
                    <Heading size='lg'>{title}</Heading>
                </WrapItem>

                <WrapItem>
                    <Wrap>
                        <>
                            {showDeleted ?
                                <WrapItem>
                                    <FormControl mt={2} display='flex' alignItems='center' maxWidth={200}>
                                        <FormLabel htmlFor='eliminados' mb='0'>
                                            Mostrar eliminadas
                                        </FormLabel>
                                        <Switch id='eliminados'
                                                onChange={handleShowDeleted}
                                        />
                                    </FormControl>
                                </WrapItem>
                                : []
                            }
                            {showSearch ?
                                <WrapItem>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<FontAwesomeIcon icon={faMagnifyingGlass} color={'lightgray'}/>}
                                        />
                                        <Input placeholder={'Buscar'} onChange={findParam}/>
                                    </InputGroup>
                                </WrapItem>
                                : []
                            }
                        </>

                        {showExport ?
                            <WrapItem>

                                <Button px={9}
                                        isLoading={isDownloading}
                                        disabled={exportData ? exportData.length === 0 : true}
                                        onClick={() => downloadFile(exportFilePath)}
                                        colorScheme={'green'} variant={'solid'} aria-label={'download xls'}
                                        leftIcon={<FontAwesomeIcon icon={faFileExcel}/>}>Exportar</Button>
                            </WrapItem>
                            : []
                        }


                        {showAddBtn ? (
                            <WrapItem>
                                {addBtnTo !== '' ?
                                    <AddButton pathTo={addBtnTo}/>
                                :
                                    <>
                                    <Button leftIcon={<FontAwesomeIcon icon={faPlus}/>}
                                            colorScheme={'blue'}
                                            onClick={() => {
                                                onOpenUserModal()
                                            }}>
                                        Agregar
                                    </Button>
                                    </>
                                }
                            </WrapItem>
                        ) : []}
                        {showBackBtn ? (
                            <WrapItem>
                                <BackButton pathTo={backButtonTo}/>
                            </WrapItem>
                        ) : []}
                        {showAddItem ? (
                            <WrapItem>
                                <Button onClick={handleOnAddItem}
                                        type={'button'}
                                        leftIcon={<FontAwesomeIcon icon={faPlus}/>}
                                        colorScheme={'green'}>
                                    Item
                                </Button>
                            </WrapItem>
                        ) : []}
                    </Wrap>
                </WrapItem>
            </Wrap>
            <Divider
                my={5}
            />
        </>
    )
}


