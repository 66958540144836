export const userValidations = {
    Name: {
        required: {
            value: true,
            message: "Debe ingresar un nombre de usuario",
        },
        /*pattern: {
            value: /^[ña-zÑA-Z0-9\s]*$/,
            message: "El nombre solo debe contener letras",
        },*/
    },
   /* Email: {
        required: {
            value: true,
            message: "Debe ingresar un correo",
        },
        pattern: {
            value: /[ña-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[ña-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[ña-z0-9](?:[ña-z0-9-]*[ña-z0-9])?\.)+[ña-z0-9](?:[ña-z0-9-]*[ña-z0-9])?/,
            message: "El correo debe tener el formato 'alias@mail.com'",
        },
    },*/
    CreatedAt: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de alta",
        },
    },
}