import {useEffect, useState} from "react";
import {useCustomToast} from "./useCustomToast";
import {api} from "../api/api";

export const useFetch = (entity: string,path: string) => {
    const toast = useCustomToast({})
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getData = async () => {
      /*  const arr = path.split("/")*/
        try {
            const res = await api.get(path)
            setData(res.data);
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }

            if (!toast.isActive("custom-toast")) {
                toast({title: `No se pudo obtener la lista de ${entity}`, status:'error'})
            }
            setData([])
            setIsLoading(false);
            return
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getData()
    }, []);

    return {
        data,
        isLoading,
        setIsLoading,
    }
}