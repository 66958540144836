import {
    Badge,
    Button,
    Divider,
    Heading, HStack, LinkBox, LinkOverlay,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader, ModalOverlay,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td, Text,
    Th,
    Thead,
    Tr,
    VStack, Wrap, WrapItem,
} from "@chakra-ui/react";
import {Item} from "../interfaces/interfaces";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faCheck, faTruck} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import * as React from "react";

export const ModalItems = ({isOpen = false, onClose = [] as any, institution = [] as any}) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const deliveryPhone = "2901404485"
    return (
        <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                scrollBehavior={'inside'}
                size={isMobile ? 'sm' : 'lg'}

            >
                <ModalOverlay/>
                <ModalContent mt={20} mx={4}>
                    <ModalHeader>
                        <Badge fontSize={18} px={3} py={1} colorScheme={'green'} variant={'solid'} borderRadius={5}>
                            {institution.Name}
                        </Badge>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Heading size={'md'}>
                            Artículos que se pueden recibir
                        </Heading>
                        <Divider my={6}/>
                        <Wrap overflowY={'scroll'} maxH={'53vh'}>
                            {
                                institution.Items ?
                                    institution.Items.map((item: Item, index: number) => (
                                        <WrapItem>
                                            <Badge p={'1'} px={'2'} key={index} borderRadius={'5'}
                                                   colorScheme={'green'}>
                                                <HStack>
                                                    <FontAwesomeIcon icon={faCheck} beat={true}/>
                                                    <Text fontSize={isMobile ? '12' : '14'}>
                                                        {item.Name}
                                                    </Text>
                                                </HStack>

                                            </Badge>
                                        </WrapItem>
                                    )) : []
                            }
                        </Wrap>

                    </ModalBody>
                    <ModalFooter>
                        <HStack>

                            <LinkBox>
                                <Badge p={'1'} px={'2'} borderRadius={'5'}
                                       colorScheme={'orange'} textOverflow={'wrap'}>
                                    <HStack px={2}>
                                        <FontAwesomeIcon icon={faTruck} beat={true}/>
                                        <Wrap>
                                            <Text whiteSpace="pre-line">
                                                Si necesitás podemos pasar a buscar tu donación haciendo click acá.
                                            </Text>
                                        </Wrap>
                                    </HStack>

                                </Badge>
                                <LinkOverlay
                                    href={'https://wa.me/+54' + deliveryPhone}
                                    target={'_blank'}/>
                            </LinkBox>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}