import {Button} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

export const BackButton = ({pathTo}: any) => {
    return (
        <Link to={pathTo}>
            <Button leftIcon={<FontAwesomeIcon icon={faArrowLeft}/>} colorScheme={'gray'}>
                Volver
            </Button>
        </Link>
    )
}