export const validations = {
    Name: {
        required: {
            value: true,
            message: "Debe ingresar un nombre",
        },
        pattern: {
            value: /^[ña-zÑA-Z0-9\s]*$/,
            message: "El nombre solo debe contener letras",
        },
    },
    Email: {
        required: {
            value: true,
            message: "Debe ingresar un correo",
        },
        pattern: {
            value: /[ña-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[ña-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[ña-z0-9](?:[ña-z0-9-]*[ña-z0-9])?\.)+[ña-z0-9](?:[ña-z0-9-]*[ña-z0-9])?/,
            message: "El correo debe tener el formato 'alias@mail.com'",
        },
    },
    /*Description: {
        required: {
            value: true,
            message: "Debe ingresar una descripción",
        }
    },*/
    Phone: {
        required: {
            value: true,
            message: "Debe ingresar un teléfono",
        },
        custom: {
            isValid: (value: any) => value?.length >= 6,
            message: "Debe ingresar al menos 6 dígitos",
        },
    },
    Address: {
        required: {
            value: true,
            message: "Debe ingresar una dirección",
        },
    },
   /* City: {
        required: {
            value: true,
            message: "Debe seleccionar una localidad",
        },
    },*/
    AccountAlias: {
        custom: {
            isValid: (value: any) => value?.length >= 6,
            message: "Debe ingresar al menos 6 dígitos",
        },
    },
    CBU: {
        custom: {
            isValid: (value: any) => value === undefined || value === '' || value?.length === 22,
            message: "Debe ingresar 22 dígitos - faltan: ",
        },
    },
    Category: {
        required: {
            value: true,
            message: "Debe seleccionar una categoria",
        },
    },
    CreatedAt: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de alta",
        },
    },
}