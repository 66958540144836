export const findInstutuionByParam = (data = [] as any, param = '') => {
    if (param.length === 0) {
        return data
    }

    param = param.toLowerCase()

    let newList = [] as any

    for (let i = 0; i < data.length; i++) {
        const obj = {
            Name: data[i].Name,
            Category: data[i].Category,
            Referents: data[i].Description,
        }

        const values = Object.values(obj)
        if (JSON.stringify(values).toLowerCase().includes(param)) {
            newList = [
                ...newList,
                data[i]
            ]
        }
    }

    return newList
}