
const RoleSuperUser = 'SuperUser'
const RoleAdmin = 'Admin'
export const RoleUser = 'User'

export const isSuperUser = (role: string) => {
    return role === RoleSuperUser
}
export const isAdmin = (role: string) => {
    return role === RoleAdmin
}

export const isUser = (role: string) => {
    return role === RoleUser
}
