import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Divider, Fade,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input, NumberInput, NumberInputField,
    SimpleGrid,
    Skeleton,
    Stack,
    Switch, Wrap
} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Referent} from "../interfaces/interfaces";
import * as React from "react";

export const Referents = ({
                              form = [] as any,
                              onInputChange = [] as any,
                              setFormState = [] as any,
                              errors = [] as any,
                              submitted = [] as any
                          }) => {

    const handleOnAddReferent = () => {
        if (!form.Referents || form.Referents.length === 0) {
            setFormState({
                ...form,
                Referents: [{ID: crypto.randomUUID(), Name: '', Phone: '', WhatsApp: true}]
            })
            return
        }

        let add = true
        for (const referent of form.Referents) {
            if (referent.Name === '' || referent.Phone === '') {
                add = false
            }
        }

        if (!add) {
            return
        }

        setFormState({
            ...form,
            Referents: [{ID: crypto.randomUUID(), Name: '', Phone: '', WhatsApp: true}, ...form.Referents]
        })
    }

    const handleDeleteReferents = (id: string) => {
        setFormState({
            ...form,
            Referents: form.Referents.filter((value: Referent) => value.ID !== id)
        })
    }

    return (
        <>
            <Stack mx={'1.5%'}>
                <HStack justify={'space-between'}>
                    <Heading size={'lg'}>Referentes</Heading>
                    <Button onClick={handleOnAddReferent}
                            type={'button'}
                            leftIcon={<FontAwesomeIcon icon={faPlus}/>}
                            colorScheme={'green'}>
                        Referente
                    </Button>
                </HStack>

                <Wrap p={3} spacing={3}>
                    {form.Referents?.map((referent: Referent, idx: number) => (
                        <Skeleton key={idx} isLoaded={true} borderRadius={'xl'}>
                            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                                <Card maxW='sm' borderRadius={'xl'}>
                                    <CardBody>

                                        <FormControl isInvalid={submitted && referent.Name === ''}
                                                     isRequired>
                                            <FormLabel>Nombre</FormLabel>
                                            <Input
                                                name={`Referents[${idx}].Name`}
                                                placeholder={"Ingrese un nombre"}
                                                type='text'
                                                value={referent.Name}
                                                onChange={onInputChange}
                                            />
                                            {submitted && referent.Name === '' ? (
                                                <FormErrorMessage>Debe ingresar un nombre</FormErrorMessage>
                                            ) : ([])}
                                        </FormControl>
                                        <FormControl mt={2}>
                                            <FormLabel>Teléfono</FormLabel>
                                            <NumberInput
                                                name={`Referents[${idx}].Phone`}
                                                value={referent.Phone}
                                            >
                                                <NumberInputField onChange={onInputChange}
                                                                  placeholder={"Ingrese un teléfono. ej: 2901334455"}/>
                                            </NumberInput>
                                        </FormControl>
                                        <FormControl alignItems='center'>
                                            <FormLabel htmlFor={'switchWhatsApp'}>WhatsApp</FormLabel>
                                            <Box>
                                                <Switch id={'switchWhatsApp'}
                                                        name={`Referents[${idx}].WhatsApp`}
                                                        colorScheme={'whatsapp'}
                                                        defaultChecked={true}
                                                        isChecked={referent.WhatsApp}
                                                        onChange={onInputChange}
                                                />
                                            </Box>
                                        </FormControl>

                                    </CardBody>
                                    <Divider color={'lightgray'}/>
                                    <CardFooter>
                                        <Button colorScheme={'red'}
                                                aria-label={`borrar ${idx}`}
                                                leftIcon={<FontAwesomeIcon icon={faTrash}/>}
                                                onClick={() => handleDeleteReferents(referent.ID)}>
                                            Borrar
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Fade>
                        </Skeleton>
                    ))}
                </Wrap>
            </Stack>
        </>
    )
}