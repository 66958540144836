import {
    Alert,
    AlertIcon,
    Badge,
    Box,
    Button,
    Collapse,
    Container,
    Divider,
    Fade,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Image,
    Input,
    NumberInput,
    NumberInputField,
    Select,
    SimpleGrid,
    Stack,
    Switch,
    Text,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {BackButton} from "../../components/BackButton";
import {useNavigate, useParams} from "react-router-dom";
import {useCustomToast} from "../../hooks/useCustomToast";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useGetObjectByID} from "../../hooks/useGetObjectByID";
import {useForm} from "../../hooks/useForm";
import {validations} from "../../helpers/validations";
import {api} from "../../api/api";
import {format, formatISO, parse, parseISO} from "date-fns";
import {SaveButton} from "../../components/SaveButton";
import {Navbar} from "../../components/Navbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleInfo, faCloudUpload, faCopy, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useFetchCategories} from "../../helpers/getCategories";
import {Referents} from "../../components/Referents";
import {Institution, User} from "../../interfaces/interfaces";
import {errorCodeAlreadyCreated, errorCodeEmailAlreadyRegistered} from "../../utils/constants";
import {randomPassword} from "../../utils/password";
import fallBackImage from "../../assets/img/not_image.webp";
import Clipboard from '@react-native-clipboard/clipboard';
import {isUser, RoleUser} from "../../store/auth/auth";
import {useSelector} from "react-redux";


export const FormInstitution = () => {
    const {id} = useParams();
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [actualEmail, setActualEmail] = useState('')
    const [userPasswordCopyText, setUserPasswordCopyText] = useState('')
    const [isPasswordCopied, setIsPasswordCopied] = useState(false)

    const today = format(new Date(), 'yyyy-MM-dd')
    const photoInputRef = useRef([] as any);
    const {object}: any =
        useGetObjectByID('institutions', id, 'la', 'institución')
    const {categories} = useFetchCategories()

    /* const [cities] = useState(['Ushuaia', 'Tolhuin', 'Río Grande'])*/
    const {role, username} = useSelector((state: any) => state.store)

    const {form, onInputChange, setFormState, errors} = useForm({
        validations,
        CreatedAt: today
    })

    useEffect(() => {
        if (object.ID) {
            setActualEmail(object.Email)
            object.CreatedAt = format(parseISO(object.CreatedAt), 'yyyy-MM-dd')
            object.DeletedAt = object.DeletedAt ? format(parseISO(object.DeletedAt), 'yyyy-MM-dd') : null
            setFormState({...object, validations})
        }
        if (isUser(role) && object.Email && object.Email !== username) {
            navigate("/app/inicio", {replace: true})
        }

    }, [object, setFormState]);

    const handlePhotoUpload = () => {
        photoInputRef.current.click()
    }

    const handlePhotoDelete = () => {
        setFormState({
            ...form,
            Logo: ''
        })
    }

    const onFileInputChange = async ({target}: any) => {
        const file = target.files[0]
        const reader = new FileReader()
        reader.onload = ({target}: any) => {
            setFormState({
                ...form,
                Logo: target.result
            })
        }
        reader.readAsDataURL(file)
    }

    const checkEmail = () => {
        if (form.Email === actualEmail || errors.Email) {
            setShowPassword(false)
            return
        }

        if (showPassword) {
            return;
        }

        let pass = randomPassword(13)
        setNewPassword(pass)

        if (form.ID) {
            setUserPasswordCopyText(`Institución: ${form.Name} 
Usuario: ${form.Email} 
Contraseña: ${pass} 
Ingreso: www.compartiendobienestar.org.ar`)
        } else {
            setUserPasswordCopyText(`Nueva institución: ${form.Name} 
Usuario: ${form.Email} 
Contraseña: ${pass} 
Ingreso: www.compartiendobienestar.org.ar`)
        }

        setShowPassword(true)
    }

    useEffect(() => {
    }, [newPassword]);

    const copyPassword = () => {
        Clipboard.setString(userPasswordCopyText)
        setIsPasswordCopied(true)
        setTimeout(() => setIsPasswordCopied(false), 1000)
    }

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        if (form.Referents && form.Referents.length > 0) {
            for (const referent of form.Referents) {
                if (referent.Name === '' || referent.Phone === '') {
                    return
                }
            }
        }

        if (errors.length !== 0) {
            console.error(errors)
            return
        }
        setIsSaving(true)

        let action = 'save'
        let status = 'guardada'
        if (id) {
            action = 'update'
            status = 'actualizada'
        }

        let institution: Institution = {
            ...form,
            CreatedAt: formatISO(parse(form.CreatedAt, 'yyyy-MM-dd', new Date())),
            DeletedAt: form.DeletedAt ? formatISO(parse(form.DeletedAt, 'yyyy-MM-dd', new Date())) : null,
            Phone: Number(form.Phone),
            City: 'Ushuaia'
        }

        if (!id) {
            let user: User = {
                ID: null,
                Name: institution.Email,
                Password: newPassword,
                Role: RoleUser,
                InstitutionID: '',
                InstitutionName: institution.Name,
                CreatedAt: formatISO(parse(form.CreatedAt, 'yyyy-MM-dd', new Date())),
                UpdatedAt: null,
                DeletedAt: null,
                Deleted: false
            }

            try {
                await api.post(`/users/${action}`, user)
            } catch (error: any) {
                if (error.response &&
                    error.response.data &&
                    error.response.data.Code === errorCodeEmailAlreadyRegistered) {
                    setIsSaving(false)
                    toast({
                        title: `Ya existe un registro guardado con el correo electrónico '${institution.Email}'!`,
                        status: 'error'
                    })

                    return
                }

                setIsSaving(false)
                toast({title: `La institución no pudo ser ${status}!`, status: 'error'})

                return
            }
        }

        if (id && form.Email !== actualEmail) {
            try {
                await api.put(`/users/update/password`, {
                    "UserName": actualEmail,
                    "Password": newPassword
                })
            } catch (error: any) {
                setIsSaving(false)
                toast({title: `La institución no pudo ser ${status}!`, status: 'error'})

                return
            }
        }


        try {
            if (action === 'update') {
                await api.put(`/institutions/${action}`, institution)
            } else {
                await api.post(`/institutions/${action}`, institution)
            }

            toast({title: `Institución ${status} correctamente!`, status: 'success'})
            navigate("/app/instituciones", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeAlreadyCreated) {
                setIsSaving(false)
                toast({
                    title: `Ya existe una institución con el nombre '${institution.Name}'!`,
                    status: 'error'
                })

                return
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeEmailAlreadyRegistered) {
                setIsSaving(false)
                toast({
                    title: `Ya existe un registro guardado con el correo electrónico '${institution.Email}'!`,
                    status: 'error'
                })

                return
            }


            setIsSaving(false)
            toast({title: `La institución no pudo ser ${status}!`, status: 'error'})
        }
    }


    return (
        <>
            <Navbar/>

            <Container mt={8}
                       maxW='100%'>
                <HStack justifyContent={"space-between"}>
                    <Heading>{id ? 'Editar institución' : 'Nueva institución'}</Heading>
                    <HStack>
                        <BackButton pathTo={'/app/instituciones'}/>
                    </HStack>
                </HStack>
                <Divider my={5}/>

                <form autoComplete={'off'}>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <Stack height={'60vh'} overflowY={'scroll'}>
                            <SimpleGrid p={3} spacing={5} minChildWidth={'72'}>
                                <VStack
                                    mb={1}
                                    justifyContent={"space-around"}
                                >
                                    <Image
                                        borderRadius={'20'}
                                        boxShadow={'xl'}
                                        src={form.Logo ? form.Logo : fallBackImage}
                                        bg='gray.300'
                                        fallbackSrc={fallBackImage}
                                    />
                                    <FormControl display={"none"}>
                                        <Input ref={photoInputRef}
                                               type={'file'}
                                               name={'file'}
                                               onChange={onFileInputChange}
                                               accept="image/!*"
                                        />
                                    </FormControl>
                                    <HStack pt={3}>
                                        <Button onClick={handlePhotoUpload}
                                                colorScheme={"teal"}
                                                leftIcon={<FontAwesomeIcon icon={faCloudUpload}/>}
                                                size={"sm"}>Subir</Button>

                                        <Button onClick={handlePhotoDelete} leftIcon={<FontAwesomeIcon icon={faTrash}/>}
                                                colorScheme={"red"}
                                                size={"sm"}>Borrar</Button>
                                    </HStack>
                                </VStack>
                                <FormControl isInvalid={submitted && errors.Name} isRequired>
                                    <FormLabel>Nombre</FormLabel>
                                    <Input
                                        name={'Name'}
                                        placeholder={"Ingrese un nombre"}
                                        type='text'
                                        value={form.Name || []}
                                        onChange={onInputChange}
                                    />
                                    {submitted && errors.Name ? (
                                        <FormErrorMessage>{errors.Name}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                <FormControl isInvalid={submitted && errors.Email} isRequired>
                                    <FormLabel>Correo</FormLabel>
                                    <Input
                                        name={'Email'}
                                        type='email'
                                        readOnly={isUser(role)}
                                        placeholder={'Ingrese un correo. ej: micorreo@mail.com'}
                                        value={form.Email || []}
                                        onChange={onInputChange}
                                        onBlur={checkEmail}
                                    />
                                    {isUser(role) ?
                                        <Badge mt={2} p={'1'} px={'2'} borderRadius={'5'}
                                               colorScheme={'blue'} textOverflow={'wrap'}>
                                            <HStack>
                                                <FontAwesomeIcon icon={faCircleInfo} fontSize={16}/>
                                                <Wrap>
                                                    <Text whiteSpace="pre-line">
                                                        el correo solo puede editarse por un administrador.
                                                    </Text>
                                                </Wrap>
                                            </HStack>

                                        </Badge>
                                        : []}
                                    {submitted && errors.Email ? (
                                        <FormErrorMessage>{errors.Email}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                <FormControl isInvalid={submitted && errors.Description}>
                                    <FormLabel>Descripción</FormLabel>
                                    <Input
                                        name={'Description'}
                                        placeholder={"Ingrese una descripción"}
                                        type='text'
                                        value={form.Description || []}
                                        onChange={onInputChange}
                                    />
                                    {submitted && errors.Description ? (
                                        <FormErrorMessage>{errors.Description}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                <FormControl isInvalid={submitted && errors.Phone} isRequired>
                                    <FormLabel>Teléfono</FormLabel>
                                    <NumberInput
                                        name={'Phone'}
                                        value={form.Phone || []}
                                    >
                                        <NumberInputField onChange={onInputChange}
                                                          placeholder={"Ingrese un teléfono. ej: 2901334455"}/>
                                    </NumberInput>

                                    {submitted && errors.Phone ? (
                                        <FormErrorMessage>{errors.Phone}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                <FormControl alignItems='center'>
                                    <FormLabel htmlFor={'switchWhatsApp'}>WhatsApp</FormLabel>
                                    <Box>
                                        <Switch id={'switchWhatsApp'}
                                                name={'WhatsApp'}
                                                colorScheme={'whatsapp'}
                                                defaultChecked={true}
                                                isChecked={form.WhatsApp}
                                                onChange={onInputChange}
                                        />
                                    </Box>
                                </FormControl>
                                <FormControl isInvalid={submitted && errors.Address} isRequired>
                                    <FormLabel>Dirección</FormLabel>
                                    <Input
                                        name={'Address'}
                                        placeholder={"Ingrese una dirección"}
                                        type='text'
                                        value={form.Address || []}
                                        onChange={onInputChange}
                                    />
                                    {submitted && errors.Address ? (
                                        <FormErrorMessage>{errors.Address}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                <FormControl isInvalid={submitted && errors.Neighborhood}>
                                    <FormLabel>Barrio</FormLabel>
                                    <Input
                                        name={'Neighborhood'}
                                        placeholder={"Ingrese un barrio"}
                                        type='text'
                                        value={form.Neighborhood || []}
                                        onChange={onInputChange}
                                    />
                                    {submitted && errors.Neighborhood ? (
                                        <FormErrorMessage>{errors.Neighborhood}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                {/* <FormControl isInvalid={submitted && errors.City} isRequired>
                                    <FormLabel>Ciudad</FormLabel>
                                    <Select name={'City'}
                                            placeholder='Seleccione una ciudad'
                                            value={form.City}
                                            onChange={onInputChange}>

                                        {cities ?
                                            cities.map((city: string, idx: number) => (
                                                <option key={idx} value={city}>{city}</option>
                                            )) : []
                                        }
                                    </Select>
                                    {submitted && errors.City ? (
                                        <FormErrorMessage>{errors.City}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>*/}
                                <FormControl isInvalid={submitted && errors.AccountAlias}>
                                    <FormLabel>Alias de cuenta</FormLabel>
                                    <Input
                                        name={'AccountAlias'}
                                        placeholder={"Ingrese un alias de la cuenta"}
                                        type='text'
                                        value={form.AccountAlias || []}
                                        onChange={onInputChange}
                                    />
                                    {submitted && errors.AccountAlias ? (
                                        <FormErrorMessage>{errors.AccountAlias}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                <FormControl isInvalid={submitted && errors.CBU}>
                                    <FormLabel>CBU</FormLabel>
                                    <NumberInput name={'CBU'}
                                                 value={form.CBU || []}
                                    >
                                        <NumberInputField onChange={onInputChange} maxLength={22}
                                                          placeholder={'Ingrese un cbu. ej: 1122334455667788990011'}/>
                                    </NumberInput>
                                    {submitted && errors.CBU ? (
                                        <FormErrorMessage>{errors.CBU} {form.CBU ? 22 - form.CBU.length : 22}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>
                                {
                                    isUser(role) ? [] :
                                        <FormControl isInvalid={submitted && errors.Category} isRequired>
                                            <FormLabel>Categoria</FormLabel>
                                            <Select name={'Category'}
                                                    placeholder='Seleccione una categoria'
                                                    value={form.Category}
                                                    onChange={onInputChange}
                                            >
                                                {categories ?
                                                    categories.map((cat: string, idx: number) => (
                                                        <option key={idx}
                                                                value={cat}>{cat}</option>
                                                    )) : []
                                                }
                                            </Select>
                                            {submitted && errors.Category ? (
                                                <FormErrorMessage>{errors.Category}</FormErrorMessage>
                                            ) : ([])}
                                        </FormControl>
                                }
                                <FormControl isInvalid={submitted && errors.CreatedAt} isRequired>
                                    <FormLabel>Fecha de alta</FormLabel>
                                    <Input
                                        disabled={true}
                                        name={'CreatedAt'}
                                        type='date'
                                        value={form.CreatedAt || []}
                                        onChange={onInputChange}
                                    />
                                    {submitted && errors.CreatedAt ? (
                                        <FormErrorMessage>{errors.CreatedAt}</FormErrorMessage>
                                    ) : ([])}
                                </FormControl>

                                {
                                    isUser(role) ? [] :
                                        <>
                                            <FormControl alignItems='center'>
                                                <FormLabel htmlFor={'switchDeleted'}>Eliminado</FormLabel>
                                                <Box>
                                                    <Switch id={'switchDeleted'}
                                                            name={'Deleted'}
                                                            colorScheme={'red'}
                                                            defaultChecked={false}
                                                            isChecked={form.Deleted}
                                                            onChange={onInputChange}
                                                    />
                                                </Box>
                                            </FormControl>
                                            <FormControl isInvalid={submitted && errors.DeletedAt}>
                                                <FormLabel>Fecha de baja</FormLabel>
                                                <Input
                                                    readOnly={true}
                                                    name={'DeletedAt'}
                                                    type='date'
                                                    value={form.DeletedAt || []}
                                                    onChange={onInputChange}
                                                />
                                                {submitted && errors.DeletedAt ? (
                                                    <FormErrorMessage>{errors.DeletedAt}</FormErrorMessage>
                                                ) : ([])}
                                            </FormControl>
                                        </>
                                }
                            </SimpleGrid>

                            <Divider my={4}/>

                            <Referents form={form} setFormState={setFormState} onInputChange={onInputChange}
                                       errors={errors}
                                       submitted={submitted}/>
                        </Stack>
                    </Fade>
                    <Collapse in={showPassword} animateOpacity>
                        <Alert status='info'
                               mt={2}
                               p={2}
                               pl={4}
                               borderRadius={10}
                               w={'fit-content'}>
                            <AlertIcon/>
                            <Wrap align={'center'}>
                                <WrapItem>
                                    <Text fontSize={16}>
                                        {form.ID ?
                                            "Se actualizará el usuario y contraseña al editar el email:"
                                            :
                                            "Se creará el siguiente usuario para la institución:"
                                        }
                                    </Text>
                                </WrapItem>
                                <WrapItem>

                                    <Button
                                        size={'content'}
                                        p={3}
                                        fontSize={16}
                                        onClick={copyPassword}
                                        variant={'solid'}
                                        colorScheme={'blue'}
                                        aria-label={'copy-password'}
                                        rightIcon={isPasswordCopied ? <FontAwesomeIcon
                                                icon={faCheck}
                                            /> :
                                            <FontAwesomeIcon
                                                icon={faCopy}
                                                beat={true}
                                            />}>
                                        <Wrap>
                                            <WrapItem>
                                                {form.Email}
                                            </WrapItem>
                                            <WrapItem>
                                                Contraseña: {newPassword}
                                            </WrapItem>
                                        </Wrap>
                                    </Button>
                                </WrapItem>
                            </Wrap>
                        </Alert>
                    </Collapse>
                    <HStack justify={'flex-end'} m={0}>
                        <SaveButton isLoading={isSaving} onSubmit={onSubmit}/>
                    </HStack>
                </form>
            </Container>
        </>
    )
}

