import {Box, Button, Center, HStack, LinkBox, LinkOverlay, Text, useColorModeValue, VStack} from "@chakra-ui/react";
import * as React from "react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Main} from "../components/Main";
import {Navbar} from "../components/Navbar";
import {faEnvelope, faHeart, faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import bgImage from "../assets/img/pexels-johannes-plenio.webp";

export const Web = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState(true)

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const {webName} = useSelector((state: any) => state.store)
    const mailTo = 'mailto:compartiendobienestarush@gmail.com?subject=Sugerencia%20para%20la%20página%20'
        + webName + '&body=Quiero%20sugerir%20'

    const mailToRegister = 'mailto:compartiendobienestarush@gmail.com?subject=Quiero%20registrar%20mi%20institución%20en%20'
        + webName

    const textColor = useColorModeValue("gray.100", "gray.900")

    return (
        <>
            <Box>
                <Navbar/>
                <Box bgImage={bgImage}
                     bgSize={'cover'}
                     bgPosition="center"
                     bgRepeat="no-repeat"
                     opacity={'0.07'}
                     zIndex={'-2000'}
                     w={'100vw'}
                     h={'100vh'}
                     position={'fixed'}
                     top={0}
                     left={0}
                />

                <Center m={10}>
                    <Text w={isMobile ? '100%' : '80%'} fontSize={isMobile ? 22 : 28} justifyContent={'center'}>
                        Somos una red solidaria para que los vecinos de Ushuaia podamos ayudarnos entre todos. Acá
                        podes encontrar quien necesita eso que querés donar.
                    </Text>
                </Center>

                <Main handleIsLoading={setIsLoading}/>

                {
                    isMobile ?
                        <>
                            <VStack position={isLoading ? 'fixed' : 'relative'} bottom={0} left={0} p={5}
                                    bg={'gray.100'}
                                    w={'100%'}
                                    color={textColor}>
                                <HStack w={'100%'} justify={'center'}>
                                    <Box color={'gray.900'} mb={3}>© 2024 | {webName}</Box>
                                </HStack>
                                <VStack w={200} justify={'center'}>
                                    <LinkBox w={'100%'}>
                                        <Button w={'100%'} fontSize={14} variant='solid' colorScheme='teal'
                                                leftIcon={<FontAwesomeIcon icon={faEnvelope} beat={true}/>}>
                                            Sugerencias
                                        </Button>
                                        <LinkOverlay href={mailTo}/>
                                    </LinkBox>

                                </VStack>
                                <HStack w={200} justify={'center'}>
                                    <LinkBox w={'100%'}>
                                        <Button w={'100%'} fontSize={14} variant='solid' colorScheme='orange'
                                                leftIcon={<FontAwesomeIcon icon={faHeart} beat={true}/>}>
                                            Ser parte
                                        </Button>
                                        <LinkOverlay href={mailToRegister}/>
                                    </LinkBox>
                                </HStack>
                                <HStack w={200} justify={'center'}>
                                    <LinkBox w={'100%'}>
                                        <Button w={'100%'} fontSize={14} variant='solid' colorScheme='blue'
                                                leftIcon={<FontAwesomeIcon icon={faRightToBracket} beat={true}/>}>
                                            Ingresar
                                        </Button>
                                        <LinkOverlay href={'/app/login'}/>
                                    </LinkBox>
                                </HStack>
                            </VStack>
                        </>
                        :
                        <HStack p={5} justify={'space-between'} bg={'gray.100'}
                                color={'black'}
                                w={'100%'}
                                position={'fixed'} bottom={0}>
                            <HStack w={'30%'}>
                                <LinkBox>
                                    <Button fontSize={14} variant='solid' colorScheme='teal'
                                            leftIcon={<FontAwesomeIcon icon={faEnvelope} beat={true}/>}>
                                        Sugerencias
                                    </Button>
                                    <LinkOverlay href={mailTo}/>
                                </LinkBox>
                            </HStack>
                            <VStack w={'30%'}>
                                <Box>© 2024 | {webName}</Box>
                            </VStack>
                            <HStack w={'30%'} justify={'flex-end'} color={textColor}>
                                <LinkBox>
                                    <Button fontSize={14} variant='solid' colorScheme='orange'
                                            leftIcon={<FontAwesomeIcon icon={faHeart} beat={true}/>}>
                                        Ser parte
                                    </Button>
                                    <LinkOverlay href={mailToRegister}/>
                                </LinkBox>
                                <LinkBox>
                                    <Button fontSize={14} variant='solid' colorScheme='blue'
                                            leftIcon={<FontAwesomeIcon icon={faRightToBracket} beat={true}/>}>
                                        Ingresar
                                    </Button>
                                    <LinkOverlay href={'/app/login'}/>
                                </LinkBox>
                            </HStack>
                        </HStack>
                }
            </Box>
        </>
    );
}

