import {
    Alert,
    AlertDescription,
    AlertTitle,
    Badge,
    Button,
    Container,
    Fade,
    FormControl,
    HStack,
    IconButton,
    Input,
    Kbd,
    Skeleton,
    Text, useColorModeValue,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleExclamation, faPlus, faTrash, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {Institution, Item} from "../../interfaces/interfaces";
import * as React from "react";
import {useEffect, useState} from "react";
import {useGetObjectByID} from "../../hooks/useGetObjectByID";
import {useForm} from "../../hooks/useForm";
import {useNavigate, useParams} from "react-router-dom";
import {useCustomToast} from "../../hooks/useCustomToast";
import {Navbar} from "../../components/Navbar";
import {CustomHeading} from "../../components/CustomHeading";
import {findByParam} from "../../helpers/findByParam";
import {SaveButton} from "../../components/SaveButton";
import {api} from "../../api/api";
import {useSelector} from "react-redux";
import {isUser} from "../../store/auth/auth";

export const Items = () => {
    const {id} = useParams();
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const {object}: any =
        useGetObjectByID('institutions', id, 'los', 'items')

    const [list, setList] = useState(object.Items || [] as Item[])
    const [initialList, setInitialList] = useState(object.Items || [])
    const [newList, setNewList] = useState(object.Items || [])
    const [hasChanges, setHasChanges] = useState(false)
    const [alreadyExistItem, setAlreadyExistItem] = useState(false)
    const [newItem, setNewItem] = useState('')

    const {role, username} = useSelector((state: any) => state.store)
    const {form, setFormState} = useForm({...object})

    const badgeColorScheme = useColorModeValue('blackAlpha', 'red')

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width < 768;
    const isTab = width >= 768 && width < 1024
    const isHD = width >= 1024 && width < 1440
    const isFullHD = width >= 1440 && width <= 1920

    const handleItemChange = ({target}: any) => {
        setNewItem(target.value)
        checkIsUniqueItem()
    }

    const handleEnterKeyUp = (event: any) => {
        if (event.key === 'Enter') {
            handleOnAddItem()
        }
    }

    const checkUnsavedValues = () => {
        setHasChanges(false)

        if (newList?.length < initialList?.length) {
            setHasChanges(true)
            return
        }

        let found = false
        for (let i = 0; i < list?.length; i++) {
            for (let j = 0; j < initialList?.length; j++) {
                if (list[i].Name.toLowerCase().trim() === initialList[j].Name.toLowerCase().trim()) {
                    found = true
                    break
                }
                found = false
            }

            if (found) {
                continue
            }

            setHasChanges(true)
            return
        }
    }

    useEffect(() => {
        checkIsUniqueItem()
    }, [newItem]);

    useEffect(() => {
        checkUnsavedValues()
    }, [newList]);

    const checkIsUniqueItem = () => {
        setAlreadyExistItem(false)
        for (let i = 0; i < newList?.length; i++) {
            if (newList[i].Name.toLowerCase().trim() === newItem.toLowerCase().trim()) {
                setAlreadyExistItem(true)
                return
            }
        }
    }

    useEffect(() => {
    }, [alreadyExistItem]);

    useEffect(() => {
        if (isUser(role) && (!object || object.Email !== username)) {
            navigate("/app/inicio", {replace: true})
        }

        if (object.ID) {
            setFormState({...object})
        }

    }, [object]);

    const handleOnAddItem = () => {
        if (newItem === '') {
            return;
        }

        if (alreadyExistItem) {
            return;
        }

        let item = {ID: crypto.randomUUID(), Name: newItem}

        if (!list || list.length === 0) {
            setList([item])
            setNewList([item])
            setNewItem('')

            return
        }

        setList([item, ...list])
        setNewList([item, ...list])

        setNewItem('')
    }

    const handleDeleteItem = (id: string) => {
        let newList = list.filter((value: Item) => value.ID !== id)
        setList(newList)
        setNewList(newList)
        setAlreadyExistItem(false)
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findByParam(newList, value))
    }

    useEffect(() => {
        setList(form.Items)
        setNewList(form.Items)
        setInitialList(form.Items)
    }, [form.Items]);


    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        if (alreadyExistItem) {
            return
        }

        form.Items = list

        setIsSaving(true)

        let action = 'update'
        let status = 'guardados'

        let institution: Institution = {
            ...form
        }

        try {
            await api.put(`/institutions/${action}`, institution)

            toast({title: `Items ${status} correctamente!`, status: 'success'})
            navigate("/app/instituciones", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            setIsSaving(false)
            toast({title: `Los items no pudieron ser ${status}!`, status: 'error'})
        }
    }

    return (
        <>
            <Navbar/>

            <Container mt={8}
                       maxW='100%'>
                <CustomHeading title={'Items'}
                               exportData={list}
                               findParam={handleFindParam}
                               backButtonTo={'/app/instituciones'}
                               showBackBtn={true}
                               showSearch={true}
                               exportFilePath={"/institutions/export_xls"}/>

                <HStack justify={'space-between'}>
                    <Wrap>
                        {
                            hasChanges ?
                                <WrapItem>
                                    <Alert py={'0.8rem'} status='warning' borderRadius={7}>
                                        <FontAwesomeIcon icon={faTriangleExclamation}/>
                                        <AlertTitle ml={2}>Hay nuevos cambios!</AlertTitle>
                                        <AlertDescription>Para grabarlos, presione el boton Guardar.</AlertDescription>
                                    </Alert>
                                </WrapItem>
                                : []
                        }
                        <WrapItem justifySelf={'normal'}>
                            {
                                alreadyExistItem ?
                                    <Alert status='error' borderRadius={7} ms={0}>
                                        <FontAwesomeIcon icon={faCircleExclamation}/>
                                        <AlertDescription ml={2}>El item
                                            <Badge px={2} mx={1} borderRadius={'7'} colorScheme={badgeColorScheme}>
                                                {newItem}
                                            </Badge>
                                            ya existe</AlertDescription>
                                    </Alert>

                                    : []
                            }
                        </WrapItem>
                        {isMobile ?
                            <AddItem newItem={newItem}
                                     handleOnAddItem={handleOnAddItem}
                                     handleEnterKeyUp={handleEnterKeyUp}
                                     handleItemChange={handleItemChange}
                            ></AddItem>
                            : []}
                    </Wrap>
                    {isMobile ? []
                        :
                        <AddItem newItem={newItem}
                                 handleOnAddItem={handleOnAddItem}
                                 handleEnterKeyUp={handleEnterKeyUp}
                                 handleItemChange={handleItemChange}
                        ></AddItem>
                    }
                </HStack>

                <Wrap spacing={3} overflowY={'scroll'} maxH={'58vh'} mt={3}>
                    {list?.map((item: Item, idx: number) => (
                        <Skeleton key={idx} isLoaded={true} borderRadius={'xl'}>
                            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                                <Wrap overflowY={'scroll'} maxH={'53vh'}>
                                    <WrapItem>
                                        <Badge p={'2'} key={idx} borderRadius={'7'} colorScheme={'green'}>
                                            <HStack ps={1}>
                                                <FontAwesomeIcon icon={faCheck}/>
                                                <Text>
                                                    {item.Name}
                                                </Text>
                                                <IconButton
                                                    size={'sm'}
                                                    color={'red'}
                                                    colorScheme={'whiteAlpha'}
                                                    aria-label={`borrar ${idx}`}
                                                    icon={<FontAwesomeIcon icon={faTrash}/>}
                                                    onClick={() => handleDeleteItem(item.ID)}>
                                                </IconButton>
                                            </HStack>
                                        </Badge>
                                    </WrapItem>
                                </Wrap>
                            </Fade>
                        </Skeleton>
                    ))}
                </Wrap>
                <HStack justify={'flex-end'}>
                    <SaveButton isLoading={isSaving} onSubmit={onSubmit}/>
                </HStack>
            </Container>
        </>
    );
}

const AddItem = ({
                     newItem = '',
                     handleItemChange = [] as any,
                     handleEnterKeyUp = [] as any,
                     handleOnAddItem = [] as any
                 }) => {

    return (
        <HStack justify={'flex-end'}>
            <Wrap align={'center'} justify={'flex-end'}>
                <WrapItem>
                    <Text>
                        Puede presionar <Kbd color={'black'}>&#8626;</Kbd> para agregar
                    </Text>
                </WrapItem>
                <WrapItem>
                    <FormControl
                        w={'80%'}
                        isRequired>
                        <Input
                            autoComplete={'off'}
                            borderRightRadius={0}
                            value={newItem}
                            name={`Name`}
                            placeholder={"Ingrese un item"}
                            type='text'
                            onChange={handleItemChange}
                            onKeyUp={handleEnterKeyUp}
                            autoFocus
                        />
                    </FormControl>
                    <Button borderLeftRadius={0} ml={'0'} onClick={handleOnAddItem}
                            type={'button'}
                            leftIcon={<FontAwesomeIcon icon={faPlus}/>}
                            colorScheme={'green'}>
                        Item
                    </Button>
                </WrapItem>
            </Wrap>
        </HStack>
    )
}