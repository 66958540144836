export function randomPassword(len: number) {
    let result = ''
    const special = '*.-_'
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let counter = 0;
    while (counter < len) {
        if (counter === Math.floor(len / 2)) {
            result += special.charAt(Math.floor(Math.random() * special.length))
            counter += 1;
        }
        result += characters.charAt(Math.floor(Math.random() * characters.length));
        counter += 1;
    }
    return result;
}